import React from "react";
import InnerPagesLayout from "../component/InnerPagesLayout";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import { about2 } from "../assets/img";


const About=()=> {
  return (
<InnerPagesLayout>
<section id="section_12" className="about_section mb-5">
            <div className="container">
            

              <div className="row align-items-center  mb-5">
              <div className="col-lg-6 col-md-6 col-xs-12 text-left">
                <AnimatedOnScroll animationIn="fadeInLeft" isVisible={true}>
                  <img src={about2} className="img img-fluid"/>
                </AnimatedOnScroll>
                </div>
                <div className="col-lg-6 col-md-6 col-xs-12">
                <div className="section_head_widget animatedParent text-left ">
                    <AnimatedOnScroll animationIn="fadeInDown" isVisible={true}>
                    
                      <h4>Why would you listen for free? </h4>
                    </AnimatedOnScroll>
                    <h6>It is your time, you should get rewarded for it.</h6>
                  </div>
                  <div className="text_widget">
                    <p>
                    In the old days, we used to buy music which we call "Pay to Listen".
But those days are gone, now we are ushering a new way of enjoying music. A new way where  the listener will not only enjoy the music but will get rewarded for doing so. 
                    </p>
                    <p>
                    Play Beats is the very first platform that allows the listeners and the fans of interacting with the artists as well as benefit from the time they spend listening to the music. Every time you listen to a track you will be rewarded for doing so as long as that album still has rewards attached to it. The best thing of it all is that our ads also pay you money we don't take any it's all for you.
                    </p>
                    <p>So,... what are you waiting for?
Start listing and get paid!</p>
                  </div>
                </div>
             
              </div>
            </div>
          </section>
  </InnerPagesLayout>
  );
}
export default About;
