import React,{useState} from "react";
import ActionButtons from "./next-back"
import AddSound from "../add-sound/AddSound";
import { ethers } from "ethers";

const AddTracks = (props) => {
  const [isAddClicked, setIsAddClicked] = useState(false);
  
  const [sounds, setSounds] = useState([]);
  const [isAddToNft, setIsAddToNft] = useState(false);

  const handleUpload = (e, data) => {
    setSounds([...sounds, { name: data.name, url: data.url, img: data.img, rewardsRate: data.rewardsRate ,
      description:data.description,
      duration:data.duration,
      advCollectionAddress: data.advCollectionAddress,
      advCardId: data.advCardId
    }]);
    setIsAddClicked(false);
  };

  const rewardsContract = props.nft.rewardContract;

  const handleRemove = (e, index) => {
    var array = [...sounds]; // make a separate copy of the array
    if (index !== -1) {
      array.splice(index, 1);
      setSounds(array);
    }
  };
  const handleLastStep = () => {
    props.lastStep();
    props.completeCallback();
  };

  const SaveTrack = async() => {
    let advCollectionAddress = "0x0000000000000000000000000000000000000000";
    let advCardId = '0';

    if(isAddToNft){
      for (let i = 0; i < sounds.length; i++) {
        // console.log("SOUNDDDD", sounds[i])
        if(props.nft.name.inputs.isAdvertise){
          advCollectionAddress = sounds[i].advCollectionAddress; // sound[i].advCollectionAddress
          advCardId = sounds[i].advCardId; // sound[i].advCardId
        }
        const ethersRewardsRate = ethers.utils.parseEther(sounds[i].rewardsRate.toString());
        await rewardsContract.addTrackToSpecificCard(
          props.nft.name.inputs.existAddress, props.nft.tokensList[0],
          sounds[i].name, sounds[i].img, sounds[i].description, sounds[i].url, ethersRewardsRate, 
          sounds[i].duration, props.nft.name.inputs.isAdvertise, advCollectionAddress , advCardId // add avertisement collection address and advertisement card id
        );
      }
    }else{
      for (let i = 0; i < sounds.length; i++) {
        if(props.nft.name.inputs.isAdvertise){
          advCollectionAddress = sounds[i].advCollectionAddress; // sound[i].advCollectionAddress
          advCardId = sounds[i].advCardId; // sound[i].advCardId
        }
        const ethersRewardsRate = ethers.utils.parseEther(sounds[i].rewardsRate.toString());
        await rewardsContract.addTrackToContract(
          props.nft.name.inputs.existAddress,
          sounds[i].name, sounds[i].img, sounds[i].description, sounds[i].url, ethersRewardsRate, 
          sounds[i].duration, props.nft.name.inputs.isAdvertise, advCollectionAddress , advCardId // add avertisement collection address and advertisement card id
        );
      }
    }
  }

  return (
    <div>
      <div className="row  form-group">
        <div className="col-lg-12">
          <div className="mb-3">
            Add Tracks to the NFT{" "}
            <button
              onClick={() => {
                setIsAddClicked(true);
              }}
              className="btn btn-danger"
            >
              Add New Track
            </button>
          </div>
          {isAddClicked && (
            <AddSound
              isAdd={true}
              handleUpload={(e, data) => handleUpload(e, data)}
            />
          )}
          {sounds &&
            sounds.length > 0 &&
            sounds.map((sound, index) => (
              <AddSound
                key={index}
                isAdd={false}
                sound={sound}
                soundIndex={index}
                handleRemove={(e, selectedIndex) =>
                  handleRemove(e, selectedIndex)
                }
              />
            ))}
        </div>
      </div>
<div className="row  form-group">
        <div className="col-lg-4 col-md-4 col-sm-12 ">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="addtoNftType"
              id="addtocontractOption"
              onChange={() => setIsAddToNft(false)}
              value={!isAddToNft}
              checked={!isAddToNft}
            />
            <label className="form-check-label" htmlFor="addtocontractOption">
              Add to Contract
            </label>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 ">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="addtoNftType"
              id="addtoNftOption"
              onChange={() => setIsAddToNft(true)}
              value={isAddToNft}
            />
            <label className="form-check-label" htmlFor="addtoNftOption">
              Add to the NFT
            </label>
          </div>
        </div>
</div>
      <div className="row  form-group">
                <div className="col-lg-12 text-center">
                  <button
                    className="btn btn-danger btn-lg w-100"
                    onClick={() => SaveTrack()}
                  >
                      Save
                  </button>
                </div>
              </div>
      <ActionButtons {...props} lastStep={handleLastStep} />
    </div>
  );
};

export default AddTracks;
