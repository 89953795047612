import ABI from "../../contracts/contractABI";
import memoizeContracts from "./loadContract";
const axios = require("axios");
const ethers = require("ethers");
const TransformedContractAddress = '0x7baebcc48219e3f2c9f51537cc7c8f7c136fe0ec';

const nftTransactions = (apiUrl, apiKey, userAddress) => {
  try {
    return axios.get(apiUrl, {
      params: {
        module: "account",
        action: "tokennfttx",
        address: userAddress,
        startblock: 0,
        endblock: 999999999,
        sort: "endblock",
        apikey: apiKey,
      },
    });
  } catch (error) {
    console.log("error: ");
  }
};

const validateURI = (tokenURI) => {

  // validation #0 url is as we expected
  let handlingStringUrlRegex = /^https:\/\/.+/;
  if (handlingStringUrlRegex.test(tokenURI)) {
    return tokenURI;
  }

  // validation #1 expected url (ipfs://.....)
  handlingStringUrlRegex = /^ipfs:\/\/.+/;
  if (handlingStringUrlRegex.test(tokenURI)) {
    return makeGatewayURL(tokenURI);
  }

  // validation #3 expected url (axderfsd.json)
  return "https://dweb.link/ipfs/" + tokenURI;
};

function makeGatewayURL(ipfsURI) {
  return ipfsURI.replace(/^ipfs:\/\//, "https://dweb.link/ipfs/");
}

const loadUserNFTData = async (chainMetaData, userAddress, requiredContracts) => {
  //const [contractAddress, setContractAddress] = useState('');
  //const contract = useMemo(() => new ethers.Contract(contractAddress, ABI, provider), [contractAddress]);
  const incomingNfts = []; // addresses for incoming nfts

  const res = await nftTransactions(chainMetaData.apiUrl, chainMetaData.apiKey, userAddress);

  let inOutTransactions = res.data.result;
  // console.log(inOutTransactions);
  let tokens = new Map();

  userAddress = userAddress.toLowerCase();
  //inOutTransactions.push({contractAddress: '0xbC64E3c46EC734BfED611A68A5979B1C7cc277A5'})


  inOutTransactions = inOutTransactions.filter(transaction => {
    return requiredContracts.has(transaction.contractAddress);
  });

  inOutTransactions.forEach(function (inOutTransaction) {

    const key = `${inOutTransaction.contractAddress},${inOutTransaction.tokenID}`;

    // by default set as sell transaction
    let buyORsell = 'sell';

    if (inOutTransaction.to == userAddress) {
      buyORsell = 'buy';
    }

    tokens.set(key, buyORsell);
  });

  for (const [key, value] of tokens.entries()) {
    if (value === 'buy') {
      incomingNfts.push(key.split(','));
    }
  }

  let tokenMetadata = incomingNfts.map((Meta) => {
    // console.log(chainMetaData.tag, Meta[0], Meta[1]);
    return getTokenUri(Meta, chainMetaData.provider);
  });

  let cards = (await Promise.all(tokenMetadata)).map(async (token) => {
    try {
      const validatedTokenURI = validateURI(token.tokenURI);
      //console.log('validated tokenURI', validatedTokenURI);
      const NFTMetaData = await (await fetch(validatedTokenURI)).json();
      //console.log('fetched metadata', NFTMetaData);
      if (!NFTMetaData.image) {
        console.log('there is no image exist!');
        return {};
      }

      const img = makeGatewayURL(NFTMetaData.image);
      // console.log("imgsss", img);
      // console.log("NFTMetaData", NFTMetaData);
      return {
        image: img,
        description: NFTMetaData.description,
        name: NFTMetaData.name,
        contractAddress: token.contractAddress,
        tokenID: token.tokenID,
        networkChainId: chainMetaData.chainID,
        NFTCreator: NFTMetaData.NFTCreator,
        isAdvertisement: NFTMetaData.isAdvertise,
        isTransformed: token.contractAddress === TransformedContractAddress,
        album: NFTMetaData.attributes[1].value
      };

    } catch (error) {
      console.log('Error in fetching data', token.tokenURI);
      return {};
    }
  });
  cards = (await Promise.all(cards)).filter((x) => Object.keys(x).length !== 0);
  return cards;
};

const getTokenUri = async (Meta, provider) => {
  // 0 -> contract , 1 -> toke-id 
  //const cont = new ethers.Contract(Meta[0], ABI, provider);
  const cont = memoizeContracts(Meta[0], ABI, provider);
  const tokenURI = await cont.tokenURI(Meta[1]);
  return { tokenURI, contractAddress: Meta[0], tokenID: Meta[1] };
};

export default loadUserNFTData;
