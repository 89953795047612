import React,{useEffect,useState} from "react";
import Menu from "./menu";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { animateScroll } from "react-scroll";
import {useHistory} from "react-router-dom";

function InnerPagesLayout({children}) {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);
  let history = useHistory();

  useEffect(() => {
    if(!window.$isValidUser){
      history.push("/");
     }
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };


  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <>
    <div id="main">
      <Menu />
      <div className="main-container">
      {children}
      </div>
    
          <button
        className={`scroll-top ${scroll > top ? "show" : ""}`}
        onClick={() => scrollToTop()}
      >
        <FontAwesomeIcon icon={faChevronUp} />
      </button>
    </div>
      <footer
      className="footer"
      data-stellar-background-ratio="0.5"
    >
        <div className="container">

          <div className="row py-3">
            <div className="col-lg-6 col-md-6 col-xs-12">
                &copy; 2021 <a href="https://nftpunks.org/" className="text-white" target="_blank">NFTPunks</a>.
            </div>
            <div className="col-lg-6 col-md-6  col-xs-12 text-right">
               Contact us: <a className="pay-link" href="mailto:info@PayBeats.com">info@PayBeats.com</a>
            </div>
          </div>
      </div>
    </footer>
    </>
  );
};

InnerPagesLayout.propTypes = {
  children: PropTypes.any
};


export default InnerPagesLayout;
