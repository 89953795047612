import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useUserAddress } from "eth-hooks";
import { Avatar } from "@mui/material";

const TransferModal = ({
  show,
  handleCloseParent,
  data,
  contract,
  transactor,
  userProvider,
  isManage=false
}) => {
  const [showModal, setShowModal] = useState(show);
  const [address, setAddress] = useState(data?data.Wallet: "");
  const [status, setStatus] = useState();
  const currentWallet = useUserAddress(userProvider);

  const handleClose = () => {
    setShowModal(false);
    handleCloseParent("0");
  };

  const handleTransfer = async () => {
    const approveTx = await transactor(contract().approve(address, isManage?data.TokenId:data.id));

    setStatus("Blockchain transaction sent, waiting confirmation...");

    const receiptApproval = await approveTx.wait();
    for (const event of receiptApproval.events) {
      if (event.event !== "Transfer") {
        continue;
      }
      break;
    }
    setStatus(`Transfer token approved`);
    const transferTx = await transactor(
      contract().transferFrom(currentWallet, address, isManage?data.TokenId:data.id)
    );

    setStatus("Blockchain transaction sent, waiting confirmation...");

    // Wait for the transaction to be confirmed, then get the token ID out of the emitted Transfer event.
    const receiptTransfer = await approveTx.wait();
    for (const event of receiptTransfer.events) {
      if (event.event !== "Transfer") {
        continue;
      }
      break;
    }
    setStatus(`Token transfer successfully to ${address}`);

    // console.log(transferTx);
    handleCloseParent(transferTx);
  };

  const handleChange = (e) => {
    setAddress(e.target.value);
  };
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      scrollable={true}
      size='lg'
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title><Avatar src={data.image} className="float-left mr-2"/>Transfer {data.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <div className='row justify-content-center'>
            <div className='col-9 text-center'>
              <div className='text-right mb-0'>
                <input
                  type='text'
                  name='walletAddress'
                  value={address}
                  onChange={handleChange}
                  placeholder='Wallet Address'
                  className="form-control"
                />
              </div>
            </div>
            {/* <div className='col-3 align-self-center'>
              <div className='single-contact-form single-contact-form__tranche text-transform-normal mb-0'>
                  <CurrencyFormat
                            thousandSeparator={true}
                            displayType='text'
                            value={amount / RobeValue}
                            suffix={'  ROBe²'}
                            decimalScale={2}
                            className="mb-0"
                          />
                
              </div>
            </div> */}
          </div>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn' onClick={handleClose}>
          Close
        </button>
        <button className='btn color-accent' onClick={handleTransfer}>
          Transfer
        </button>
        {status}
      </Modal.Footer>
    </Modal>
  );
};

export default TransferModal;
