import React, { useState } from "react";
import { removeIcon, uploadIcon } from "../../assets/img/index";

const AddSound = ({ isAdd, handleUpload, sound, soundIndex, handleRemove }) => {
  const IntialInputs = () => ({
    inputs: {
      name: "",
      url: "",
      img: "",
      rewardsRate: "",
      duration: "",
      description: "",
      advCollectionAddress: "",
      advCardId: "",
    },
  });
  const [state, setState] = useState(IntialInputs());
  const [isAddToNft, setIsAddToNft] = useState(false);

  const handleChange = (e) => {
    const { value, name } = e.target;
    const { inputs } = state;

    inputs[name] = value;
    setState({
      ...state,
      inputs,
    });
  };
  return (
    <div className="row  form-group mb-4 align-items-center">
      <div className="col">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-2">
            <input
              type="text"
              name="name"
              placeholder="Name"
              onChange={(e) => handleChange(e)}
              className="form-control"
              value={sound ? sound.name : state.inputs.name}
              disabled={isAdd ? false : true}
            />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-2">
            <input
              type="text"
              name="description"
              placeholder="Track Description"
              onChange={(e) => handleChange(e)}
              className="form-control"
              value={sound ? sound.description : state.inputs.description}
              disabled={isAdd ? false : true}
            />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-2">
            <input
              type="text"
              name="img"
              placeholder="Image Source"
              onChange={(e) => handleChange(e)}
              className="form-control"
              value={sound ? sound.img : state.inputs.img}
              disabled={isAdd ? false : true}
            />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-2">
            <input
              type="number"
              name="rewardsRate"
              placeholder="Rewards Rate per Sec"
              onChange={(e) => handleChange(e)}
              className="form-control"
              value={sound ? sound.rewardsRate : state.inputs.rewardsRate}
              disabled={isAdd ? false : true}
            />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-2">
            <input
              type="number"
              name="duration"
              placeholder="Track Duration per Sec"
              onChange={(e) => handleChange(e)}
              className="form-control"
              value={sound ? sound.duration : state.inputs.duration}
              disabled={isAdd ? false : true}
            />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-2">
            <input
              type="text"
              name="url"
              placeholder="URL"
              onChange={(e) => handleChange(e)}
              className="form-control"
              value={sound ? sound.url : state.inputs.url}
              disabled={isAdd ? false : true}
            />
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-2">
            <input
              type="text"
              name="advCollectionAddress"
              placeholder="advCollectionAddress"
              onChange={(e) => handleChange(e)}
              className="form-control"
              value={sound ? sound.advCollectionAddress : state.inputs.advCollectionAddress}
              disabled={isAdd ? false : true}
            />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-2">
            <input
              type="text"
              name="advCardId"
              placeholder="advCardId"
              onChange={(e) => handleChange(e)}
              className="form-control"
              value={sound ? sound.advCardId : state.inputs.advCardId}
              disabled={isAdd ? false : true}
            />
          </div>


        </div>
      </div>
      <div className="col-auto text-center">
        {isAdd ? (
          <img
            src={uploadIcon}
            alt="upload"
            className="cursor-pointer"
            width="30px"
            onClick={(e, data) => {
              handleUpload(
                e,
                (data = {
                  name: state.inputs.name,
                  url: state.inputs.url,
                  rewardsRate: state.inputs.rewardsRate,
                  img: state.inputs.img,
                  description:state.inputs.description,
                  duration:state.inputs.duration,
                  advCollectionAddress:state.inputs.advCollectionAddress,
                  advCardId:state.inputs.advCardId
                })
              );
            }}
          />
        ) : (
          <img
            src={removeIcon}
            alt="remove"
            width="30px"
            className="cursor-pointer"
            onClick={(e) => {
              handleRemove(e, soundIndex);
            }}
          />
        )}
      </div>
    </div>
  );
};
export default AddSound;
