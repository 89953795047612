import React, { useEffect, useState, useCallback } from "react";
import InnerPagesLayout from "../component/InnerPagesLayout";
import { useSelector } from "react-redux";
import {Tabs,Tab} from "react-bootstrap";
import MyNFTs from "../component/MyNFTs";
import MyRewards from "../component/myRewards";


const MyCreations = () => {
  
  const authedUser = useSelector((state) => state.getData.authedUser);

  const [key, setKey] = useState("nfts");
  return (
    <InnerPagesLayout>
      <div className="dg__service__area service-space bg--white">
        <div className="container">
          {/* video popup */}
          {/* {userMetadata === "" ? ( */}
          {authedUser === "false" ? (
            <div className="row justify-content-center mt-5">
              <div className="col-lg-12 col-md-12 col-sm-12 text-center mb-4">
                <h3 className="text-accent">you don't have access</h3>
                <p className="text-white">You should Login first</p>
              </div>
            </div>
          ) : (
            <div className='col-lg-12 profile-tabs'>
            <Tabs
              className='my-5 d-flex justify-content-center'
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey='nfts' title='My Created Music NFTS'>
                {key === "nfts" && <MyNFTs isCreation={true} />}
              </Tab>
              <Tab eventKey='vesting' title='My Payouts'>
              {key === "vesting" &&<MyRewards  isCreation={true}/>}
              </Tab>
            </Tabs>{" "}
          </div>
          )}
        </div>
      </div>
    </InnerPagesLayout>
  );
};
export default MyCreations;
