// API token for nft.storage. Please sign up and replace this with your own key from https://nft.storage/manage/
export const NFT_STORAGE_KEY =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweGMwNkUyM0FjOUE0MzI1YkI2OUViNzc2NjVkMzI1MjdiRDVEYTc1N2UiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY0NDUxNzE5Nzk2NSwibmFtZSI6InNlcnVtTkZUcyJ9.VUa5U_mk85L3hpT2s2pTXCYiZozFA338I1JlCF0as94";

// Infura project ID. If you're building a real app based on this, please replace with your own from https://infura.io/dashboard/ethereum
export const INFURA_ID = "88c8b23728d749a3bccb188917e9ec27";
// "69f75bb89ba74822b512b464ef1495c9";

// Key for Etherscan.io. If you're building a real app based on this, please replace with your own from https://etherscan.io/myapikey
export const ETHERSCAN_KEY = "X24TUPSYNIQQ6W9PAUTWTRS2PRFRB55X67";
// "6EAGC2DEG6PP3M1GP1I86QJPQAUE4ESW1W";

// BLOCKNATIVE ID FOR Notify.js:
export const BLOCKNATIVE_DAPPID = "444787df-beb3-4093-bffe-a9efbafa8d7e";
// "0b58206a-f3c0-4701-a62f-73c7243e8c77";

export const DEFAULT_CONTRACT_NAME = "PaybeatsNFT";

export const PUBLIC_ADDRESS = "0x204D6A18C33731c7D2592C00d64EB6248Bb8e32F";
export const PRIVATE_KEY =
  "4ff4e4322994486d89f53839f19fa7be7488745c3b580bf8f799eadb6107937d";

export const IPFS_STORAGE_URL = "https://dweb.link/ipfs/";
// 0xbC64E3c46EC734BfED611A68A5979B1C7cc277A5
export const NFT_OLD_ADDRESS = "0x4662AdC1052e4a84Eaf10FfEE8BB34B09C6e3a98";
export const NFT_TRACKS_ADDRESS = "0x3f7B5Ff9DBBbf063ba6C67462F0eDb65f9c64015";
export const PLAY_VESTING_CONTRACT =
  "0x98045Ff33077db48dcef1085df82C15932D6314c";

// 0x8f8Ce1a959e9Fb8f33b55e2f8ACA6BE57021616B
export const REWARDS_CONTRACT = "0x8f8Ce1a959e9Fb8f33b55e2f8ACA6BE57021616B";

export const NETWORKS = {
  localhost: {
    name: "localhost",
    color: "#666666",
    chainId: 31337,
    blockExplorer: "",
    rpcUrl: "http://" + window.location.hostname + ":8545",
  },
  mainnet: {
    name: "mainnet",
    color: "#ff8b9e",
    chainId: 1,
    rpcUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
    blockExplorer: "https://etherscan.io/",
  },
  kovan: {
    name: "kovan",
    color: "#7003DD",
    chainId: 42,
    rpcUrl: `https://kovan.infura.io/v3/${INFURA_ID}`,
    blockExplorer: "https://kovan.etherscan.io/",
    faucet: "https://gitter.im/kovan-testnet/faucet", // https://faucet.kovan.network/
  },
  rinkeby: {
    name: "rinkeby",
    color: "#e0d068",
    chainId: 4,
    rpcUrl: `https://rinkeby.infura.io/v3/${INFURA_ID}`,
    faucet: "https://faucet.rinkeby.io/",
    blockExplorer: "https://rinkeby.etherscan.io/",
  },
  ropsten: {
    name: "ropsten",
    color: "#F60D09",
    chainId: 3,
    faucet: "https://faucet.ropsten.be/",
    blockExplorer: "https://ropsten.etherscan.io/",
    rpcUrl: `https://ropsten.infura.io/v3/${INFURA_ID}`,
  },
  goerli: {
    name: "goerli",
    color: "#0975F6",
    chainId: 5,
    faucet: "https://goerli-faucet.slock.it/",
    blockExplorer: "https://goerli.etherscan.io/",
    rpcUrl: `https://goerli.infura.io/v3/${INFURA_ID}`,
  },
  xdai: {
    name: "xdai",
    color: "#48a9a6",
    chainId: 100,
    price: 1,
    gasPrice: 1000000000,
    rpcUrl: "https://dai.poa.network",
    faucet: "https://xdai-faucet.top/",
    blockExplorer: "https://blockscout.com/poa/xdai/",
  },
  matic: {
    name: "matic",
    color: "#2bbdf7",
    chainId: 137,
    price: 1,
    gasPrice: 1000000000,
    rpcUrl: "https://rpc-mainnet.maticvigil.com",
    faucet: "https://faucet.matic.network/",
    blockExplorer: "https://explorer-mainnet.maticvigil.com/",
  },
  mumbai: {
    name: "mumbai",
    color: "#92D9FA",
    chainId: 80001,
    price: 1,
    gasPrice: 1000000000,
    rpcUrl: process.env.REACT_APP_MUMBAI_RPC_URL,
    faucet: "https://faucet.matic.network/",
    blockExplorer: "https://mumbai.polygonscan.com/",
  },
  bsc_testnet: {
    name: "Smart Chain - Testnet",
    color: "#FCD535",
    chainId: 97,
    rpcUrl: `https://data-seed-prebsc-1-s1.binance.org:8545`,
    faucet: "https://testnet.binance.org/faucet-smart/",
    blockExplorer: "https://testnet.bscscan.com/",
  },
  bsc_mainnet: {
    name: "Smart Chain - Mainnet",
    color: "#FCD199",
    chainId: 56,
    rpcUrl: `https://bsc-dataseed.binance.org/`,
    blockExplorer: "https://bscscan.com",
  },
};

// eslint-disable-next-line consistent-return
export const NETWORK = (chainId) => {
  for (const n in NETWORKS) {
    if (NETWORKS[n].chainId === chainId) {
      return NETWORKS[n];
    }
  }
};
