import React, { useState } from "react";
import "./assets/style.scss";
import "./App.css";
import Home from "./pages/home";
import { Route, Switch, HashRouter } from "react-router-dom";
import About from "./pages/about";
import PaybeatsTokens from "./pages/paybeatsTokens";
import Contact from "./pages/contact";
import ManageTracks from "./pages/manageTracks";
import AddNft from "./pages/addNft";
import Profile from "./pages/profile";
import ManageMyNfts from "./pages/manageMyNfts";
import MP3Player from "./component/MP3Player";
import MyLibrary from "./pages/mylibrary";
import MyCreations from "./pages/mycreations";
import MusicBin from "./pages/musicbin";
import LockScreen from "./pages/LockScreen";
import MyRewardsPage from "./pages/myrewards";

function App() {
  return (
    <>
      <HashRouter>
     <MP3Player/>
        <Switch>
          <Route exact path="/" component={LockScreen} />
          <Route path="/home" component={Home} />
          <Route path="/profile" component={Profile} />
          <Route path="/about" component={About} />
          <Route path="/tokens" component={PaybeatsTokens} />
          <Route path="/contact" component={Contact} />
          <Route path="/manage-tracks" component={ManageTracks} />
          <Route path="/add-nft" component={AddNft} />
          <Route path="/manage-mynfts" component={ManageMyNfts} />
          <Route path="/my-library" component={MyLibrary} />
          <Route path="/my-rewards" component={MyRewardsPage} />
          <Route path="/my-creations" component={MyCreations} />
          <Route path="/music-bin" component={MusicBin} />
        </Switch>
      </HashRouter>
    </>
  );
}

export default App;
