import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { parseUnits } from "@ethersproject/units";
import CurrencyFormat from "react-currency-format";
import { Avatar } from "@mui/material";

const SaleModal = ({ show, handleCloseParent, data, contract, transactor }) => {
  const [showModal, setShowModal] = useState(show);
  const [amount, setAmount] = useState(0);
  const [status, setStatus] = useState();
  const [selectedCurr, setSelectedCurr] = useState();

  const handleClose = () => {
    setShowModal(false);
    handleCloseParent();
  };

  const handleSale = async () => {
    const tx = await transactor(
      contract().setTokenSale(data.id, parseUnits(amount, "ether"))
    );

    setStatus("Blockchain transaction sent, waiting confirmation...");

    const receipt = await tx.wait();
    for (const event of receipt.events) {
      if (event.event !== "Transfer") {
        continue;
      }
      break;
    }
    setStatus(`Selling price set ${amount}`);
    // console.log(tx);
    handleClose();
  };

  const handleChange = (e, isAmount) => {
    if (isAmount) {
      setAmount(e.target.value);
    } else {
      setSelectedCurr(e.target.value);
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      scrollable={true}
      size='lg'
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title><Avatar src={data.image} className="float-left mr-2"/>List {data.name} for Sale</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='contact-form'>
            <div className='row text-center'>
              <div className='text-right mb-3 col'>
                <input
                  type='number'
                  name='amount'
                  value={amount}
                  onChange={(e) => handleChange(e, true)}
                  placeholder='Amount'
                  className="form-control"
                />
              </div>
              <div className='text-right mb-0 col'>
                <select onChange={(e) => handleChange(e, false)} className="form-control">
                  <option value='0'>MATIC</option>
                  <option value='1'>USDC</option>
                </select>
              </div>
            {/* <div className='col-3 align-self-center'>
              <div className='single-contact-form single-contact-form__tranche text-transform-normal mb-0'>
                  <CurrencyFormat
                            thousandSeparator={true}
                            displayType='text'
                            value={amount / RobeValue}
                            suffix={'  ROBe²'}
                            decimalScale={2}
                            className="mb-0"
                          />
                
              </div>
            </div> */}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn' onClick={handleClose}>
          Close
        </button>
        <button className='btn color-accent' onClick={handleSale}>
          List for Sale
        </button>
        {status}
      </Modal.Footer>
    </Modal>
  );
};

export default SaleModal;
