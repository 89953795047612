import React, { useCallback, useEffect, useState } from 'react';
import { Web3Provider } from '@ethersproject/providers';
import WalletConnectProvider from '@walletconnect/web3-provider';
// import { useUserAddress } from 'eth-hooks';
import Web3Modal from 'web3modal';
import { INFURA_ID } from '../constants';
import { useUserProvider } from '.';

const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: INFURA_ID,
      },
    },
  },
});

function useAddress() {
  const [injectedProvider, setInjectedProvider] = useState();
  const userProvider = useUserProvider(injectedProvider, undefined);
  // const address = useUserAddress(userProvider);

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new Web3Provider(provider));
  }, [setInjectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  return {
    injectedProvider,
    web3Modal,
    loadWeb3Modal,
    userProvider,
    logoutOfWeb3Modal,
  };
}

/* eslint-disable */
(window ).ethereum &&
  (window ).ethereum.on('chainChanged', (chainId) => {
    web3Modal.cachedProvider &&
      setTimeout(() => {
        window.location.reload();
      }, 1);
  });

(window ).ethereum &&
  (window ).ethereum.on('accountsChanged', (accounts) => {
    web3Modal.cachedProvider &&
      setTimeout(() => {
        (window ).location.reload();
      }, 1);
  });
/* eslint-enable */

export default useAddress;
