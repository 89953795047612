import React ,{useState} from "react";
import ActionButtons from "./next-back"
import { ethers } from "ethers";

const LoadTokens = (props) => {
  const [rewards, setRewards] = useState("");
  // console.log("props", props);
  const transactor = props.nft.TRANSACTOR;
  const RewardContract = props.nft.rewardContract;
  
  const LoadNftCard = async() => {
    const realAmount = ethers.utils.parseEther(rewards.toString());
    // console.log("realAMount", realAmount);
    const transaction = transactor(RewardContract.addBeatsToCard(
      props.nft.name.inputs.existAddress, props.nft.tokensList[0], realAmount
    ));
  }

  return (
    <div>
      <div className="row  form-group">
            <div className="col-lg-6 text-center">
          {/* <h1>{props.nft.name}</h1> */}
              How much rewards each will have?
            </div>
            <div className="col-lg-6 text-center">
              <input
                type="number"
                name="rewards"
                placeholder="Rewards in Beats tokens"
                onChange={(e)=>setRewards(e.target.value)}
                className="form-control"
                value={rewards}
              />
            </div>
          </div>
          <div className="row  form-group">
                <div className="col-lg-12 text-center">
                  <button
                    className="btn btn-danger btn-lg w-100"
                    onClick={() => LoadNftCard()}
                  >
                      Load
                  </button>
                </div>
              </div>
      <br />
      <ActionButtons {...props}  />
    </div>
  );
};

export default LoadTokens;
