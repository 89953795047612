const networksMetaData = {
  1: {
    apiUrl: "https://api.etherscan.io/api",
    apiKey: "9KMZ5RYD85PSPP24U9MJP1KHHDNFHWWX3W",
    tag: "Eth",
    chainID: 1,
  },
  56: {
    apiUrl: "https://api.bscscan.com//api",
    apiKey: "IWXPK172VVMT18ZXPAT4ESZC896CQGM6X1",
    tag: "BSC",
    chainID: 56,
  },
  137: {
    apiUrl: "https://api.polygonscan.com/api",
    apiKey: "3J2R52FQ2AY1Z8S4BGAQYUKVNS85EZGBUT",
    tag: "POLYGON",
    chainID: 137,
  },
  4: {
    apiUrl: "https://api-rinkeby.etherscan.io/api",
    apiKey: "9KMZ5RYD85PSPP24U9MJP1KHHDNFHWWX3W",
    tag: "ETH",
    chainID: 4,
  },
  97: {
    apiUrl: "https://api-testnet.bscscan.com/api",
    apiKey: "IWXPK172VVMT18ZXPAT4ESZC896CQGM6X1",
    tag: "BSC",
    chainID: 97,
  },
  80001: {
    apiUrl: "https://api-testnet.polygonscan.com/api",
    apiKey: "XCA4WIYUHUIYJKT9CW4DZ27AMUUERDP4E7",
    tag: "POLYGON",
    chainID: 80001,
  },
};

export default networksMetaData;
