import React, { useState, useCallback, useEffect } from "react";
import ActionButtons from "./next-back";

import { Spinner } from "react-bootstrap";
import ImageUploader from "react-images-upload";
import { NFTStorage } from "nft.storage";

import { Web3Provider } from "@ethersproject/providers";
import useGasPrice from "../../connectors/hooks/GasPrice";
import useContractLoader from "../../connectors/hooks/ContractLoader";
import useUserProvider from "../../connectors/hooks/UserProvider";
import localProvider from "../../connectors/helpers/Provider";
import Transactor from "../../connectors/helpers/Transactor";
import ImportNft from "./importNFT";
import {
  NFT_STORAGE_KEY,
  DEFAULT_CONTRACT_NAME,
  NETWORKS,
} from "../../connectors/constants";
import { useSelector } from "react-redux";

const AddNFTData = (props) => {
  const authedUser = useSelector((state) => state.getData.authedUser);

  const [loading, setLoading] = useState(true);

  const [injectedProvider, setInjectedProvider] = useState();
  const [OldContract, setOldContract] = useState();
  const userProvider = useUserProvider(injectedProvider, localProvider);
  const NftContracts = useContractLoader(userProvider, "Old");
  const signer = userProvider?.getSigner();

  const gasPrice = useGasPrice(NETWORKS.mumbai, "fast");
  const transactor = Transactor(injectedProvider, gasPrice);

  const loadWeb3Modal = useCallback(async () => {
    setInjectedProvider(new Web3Provider(window.ethereum));
  }, [setInjectedProvider]);

  useEffect(() => {
    loadWeb3Modal();
  }, [loadWeb3Modal]);

  const loadNftContract = useCallback(async () => {
    const OldContract = NftContracts ? NftContracts["PaybeatsNFT"] : "";
    OldContract && setOldContract(OldContract);
    // console.log("i'm here, and this is Minting", OldContract);
    !!OldContract && setLoading(false);
    // console.log('loading ', loading);
  }, [NftContracts]);

  useEffect(() => {
    loadNftContract();
  }, [loadNftContract]);

  const sendData = () => {
    const nftData = { name: "nft1" };
    props.nextStep();
    props.ApplyNftData(nftData);
  };

  const IntialInputs = () => ({
    inputs: {
      name: "",
      owner: "",
      category: "",
      description: "",
      isAdvertise: false,
      existAddress: "",
      numberOfCards: "",
      rewards: "",
    },
  });
  const [state, setState] = useState(IntialInputs());
  const [image, setImage] = useState();
  const [minting, setMinting] = useState(false);
  const [isAddNew, setIsAddNew] = useState(true);

  const handleChange = (e) => {
    const { value, name, type, checked } = e.target;
    const { inputs } = state;

    inputs[name] = type === "checkbox" ? checked : value;
    setState({
      ...state,
      inputs,
    });
  };

  const onDrop = (picture) => {
    setImage(picture);
  };

  const UploadMetaData = async (
    name,
    image,
    category,
    price,
    description,
    isAdvertise,
    album
  ) => {
    const currentAddress = await signer.getAddress();
    const client = new NFTStorage({ token: NFT_STORAGE_KEY });
    // console.log("Created Client", client);
    const metadata = await client.store({
      name,
      description,
      image,
      price,
      isAdvertise,
      NFTCreator: currentAddress,
      attributes: [
        {
          trait_type: "Category",
          value: category,
        },
        {
          trait_type: "album",
          value: album,
        },
      ],
    });

    const metadataURI = metadata.url.replace(/^ipfs:\/\//, "");
    // console.log("metadata URI", metadataURI);
    return metadataURI;
  };

  const handleSave = async () => {
    debugger;
    const currentAddress = await signer.getAddress();
    setMinting(true);
    const {
      name,
      owner,
      category,
      description,
      price,
      isAdvertise,
      numberOfCards,
      album,
    } = state.inputs;
    // console.log("state.inputs", state.inputs);
    const metaURI = await UploadMetaData(
      name,
      image[0],
      category,
      price,
      description,
      isAdvertise,
      album
    );

    try {
      await transactor(
        OldContract.mintCollectable(
          owner,
          metaURI,
          name,
          album,
          10,
          category,
          numberOfCards,
          isAdvertise
        )
      );
      setMinting(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <div className="row  form-group">
        <div className="text-center col-lg-6 ">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="addNftType"
              id="addnftOption"
              onChange={() => setIsAddNew(true)}
              value={isAddNew ? true : false}
              checked={isAddNew ? true : false}
            />
            <label className="form-check-label" htmlFor="addnftOption">
              Add New NFT
            </label>
          </div>
        </div>
        <div className="text-center col-lg-6 ">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="addNftType"
              id="importnftOption"
              onChange={() => setIsAddNew(false)}
              value={isAddNew ? false : true}
            />
            <label className="form-check-label" htmlFor="importnftOption">
              Import Existing NFT
            </label>
          </div>
        </div>
      </div>
      {isAddNew ? (
        <>
          <div className="row  form-group">
            <div className="col-lg-12 text-center">
              <ImageUploader
                singleImage
                withIcon={true}
                buttonText="Choose images"
                onChange={onDrop}
                imgExtension={[".jpg", ".gif", ".png"]}
                maxFileSize={20209230}
                withPreview={true}
                label={"Max file size: 20mb, accepted: jpg|gif|png"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7 text-center  form-group">
              <input
                type="text"
                name="owner"
                placeholder="Owner"
                onChange={(e) => handleChange(e)}
                className="form-control"
                value={state.inputs.owner}
              />
            </div>
            <div className="col-lg-5 text-center  form-group">
              <input
                type="number"
                name="numberOfCards"
                placeholder="Number Of Cards"
                onChange={(e) => handleChange(e)}
                className="form-control"
                value={state.inputs.numberOfCards}
              />
            </div>
          </div>

          <div className="row  form-group">
            <div className="col-lg-12 text-center">
              <input
                type="text"
                name="name"
                placeholder="Name"
                onChange={(e) => handleChange(e)}
                className="form-control"
                value={state.inputs.name}
              />
            </div>
          </div>
          <div className="row  form-group">
            <div className="col-lg-12 text-center">
              <textarea
                name="album"
                placeholder="Album"
                onChange={(e) => handleChange(e)}
                className="form-control"
                value={state.inputs.album}
              />
            </div>
          </div>
          <div className="row  form-group">
            <div className="col-lg-12 text-center">
              <select
                onChange={(e) => handleChange(e)}
                name="category"
                value={state.inputs.category}
                className="form-control"
              >
                <option value="Old">Old</option>
              </select>
            </div>
          </div>
          <div className="row  form-group">
            <div className="col-lg-12 text-center">
              <textarea
                name="description"
                placeholder="Description"
                onChange={(e) => handleChange(e)}
                className="form-control"
                value={state.inputs.description}
              />
            </div>
          </div>
          <div className="row  form-group">
            <div className="col-lg-12 ml-4">
              <input
                className="form-check-input"
                type="checkbox"
                checked={state.inputs.isAdvertise}
                id="isAdvertise"
                name="isAdvertise"
                onChange={(e) => handleChange(e)}
              />
              <label className="form-check-label" htmlFor="isAdvertise">
                is Advertisement
              </label>
            </div>
          </div>
          <div className="row  form-group">
            <div className="col-lg-12 text-center">
              <button
                onClick={handleSave}
                className="btn btn-danger btn-lg w-100"
                disabled={
                  image?.length <= 0 ||
                  state.inputs.name === "" ||
                  state.inputs.numberOfCards === ""
                }
              >
                {minting ? (
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    className="text-accent"
                  />
                ) : (
                  "Mint!"
                )}
              </button>
            </div>
          </div>
          <ActionButtons {...props} nextStep={sendData} />
        </>
      ) : (
        <ImportNft {...props} ApplyNftData={props.ApplyNftData} />
      )}

      <br />
    </div>
  );
};

export default AddNFTData;
