import React, { useState, useCallback, useEffect } from "react";
import ReactJkMusicPlayer from "react-jinke-music-player";
import "react-jinke-music-player/assets/index.css";
import { useSelector } from "react-redux";
import useUserProvider from "../../connectors/hooks/UserProvider";
import localProvider from "../../connectors/helpers/Provider";
import { Web3Provider } from "@ethersproject/providers";
import { useDispatch } from "react-redux";
import {
  setIsPlayedNFT,
  setPlayedNFT,
  setplayList,
} from "../../store/actions/action";
import { useLocation } from "react-router-dom";
import ABI from "../../connectors/contracts/ABI";
import { env } from "process";
import { 
  rewards_contract, 
  web3_rewards_instance
 } from "../../utils/rewards-contract";
const Web3 = require("web3");
const HDWalletProvider = require("truffle-hdwallet-provider-privkey");
const RewardABI =
  require("../../connectors/contracts/RewardsContract.json").abi;
const ownerOfABI = require("../../connectors/contracts/ownerOf.json").abi;

function MP3Player() {
  const playList = useSelector((state) => state.getData.playList);
  const isPaused = useSelector((state) => state.getData.isPaused);
  const [scheduleId, setScheduleId] = useState();

  const [injectedProvider, setInjectedProvider] = useState();
  const [audioInstance, setAudioInstance] = useState();
  const userProvider = useUserProvider(injectedProvider, localProvider);

  const signer = userProvider?.getSigner();
  const dispatch = useDispatch();

  const maticNode = process.env.REACT_APP_MUMBAI_RPC_URL;

  const custodian = {
    public: "0x204D6A18C33731c7D2592C00d64EB6248Bb8e32F",

    private: [
      "4ff4e4322994486d89f53839f19fa7be7488745c3b580bf8f799eadb6107937d",
    ],
  };

  const RewardsAddress = "0x8f8Ce1a959e9Fb8f33b55e2f8ACA6BE57021616B";

// const initialRewardsContract = ()=>{
//   const provider = new HDWalletProvider(custodian.private, maticNode);
//   const web3 = new Web3(provider.engine);

//   const rewards_contract = new web3.eth.Contract(
//     RewardABI,

//     RewardsAddress
//   ).methods;
//   return rewards_contract
// }

  const loadWeb3Modal = useCallback(async () => {
    setInjectedProvider(new Web3Provider(window.ethereum));
  }, [setInjectedProvider]);

  useEffect(() => {
    if (audioInstance) {
      audioInstance.pause();
    }
  }, [isPaused]);
  useEffect(() => {
    loadWeb3Modal();
  }, [loadWeb3Modal]);

  const audioPaused = async (track) => {
    dispatch(setPlayedNFT({}));
    dispatch(setIsPlayedNFT("false"));
    if (!track.isDemo) {
      // console.log('Paused');
      // console.log('track', track);

      try {
        const R_Contract = rewards_contract()
        R_Contract.endVestingSchedule(scheduleId).send({
          from: "0x204D6A18C33731c7D2592C00d64EB6248Bb8e32F",
          chainId: 80001,
          gas: 3000000,
          gasPrice: 10000000000,
        });
      } catch (e) {
         console.log('check record pause');
      }
    }
  };

  const audioPlayed = async (track) => {
    dispatch(setPlayedNFT(track));
    dispatch(setIsPlayedNFT("true"));
    // console.log("played!");
    if (!track.isDemo) {
      // console.log('track', track);

      let creator = track.nftCreator;

      let tokenIntId;
      track.trackTokenId == undefined
        ? (tokenIntId = parseInt(track.trackTokenId, 16))
        : (tokenIntId = track.nftTokenId);
      // console.log("tokenIndId", tokenIntId)
      const trackIntId = parseInt(track.trackId);
      const currentAddress = await signer.getAddress();
      let vestingAddress;
      let vestingCard;

      if (track.isAdvertisement) {
        // console.log("here")
        vestingAddress = track._advCollectionAddress;
        track._advCardId.hex == undefined
          ? (vestingCard = parseInt(track._advCardId._hex, 16))
          : (vestingCard = parseInt(track._advCardId.hex, 16));
          const web3= web3_rewards_instance()
        let advCont = new web3.eth.Contract(
          ownerOfABI,
          track._advCollectionAddress
        ).methods;
        // console.log("advCont", advCont)
        // console.log("noPar", track._advCardId)
        // console.log("Par", parseInt(track._advCardId))
        // console.log("track._advCardId.hex", parseInt(track._advCardId.hex));
        // console.log("track._advCardId", track._advCardId);

        creator = await advCont.ownerOf(parseInt(track._advCardId.hex)).call();
        // console.log("inside", creator);
      } else {
        vestingAddress = track.contractAddress;
        vestingCard = tokenIntId;
      }
      // console.log("vestingAddress", vestingAddress);
      // console.log("vestingCard", vestingCard);
      if (track.isNftSpecific) {
        try {
          // console.log("EE")
          const R_Contract = rewards_contract()
          const hash = await R_Contract
            .createVestingSchedule(
              currentAddress,
              track.contractAddress, // token address
              creator,
              vestingAddress, // adv token address
              vestingCard, // adv token id
              tokenIntId, // token id
              trackIntId
            )
            .send({
              from: "0x204D6A18C33731c7D2592C00d64EB6248Bb8e32F",
              chainId: 80001,
              gas: 9000000,
              gasPrice: 50000000000,
            });
          // console.log("hash", hash);
          setScheduleId(hash.events.ScheduleAdded.returnValues.vestingId);
        } catch (e) {
          console.log(e);
        }
      } else {
        // general track
        // console.log("HERE")
        // console.log("TRACKKK", track)
        try {
          // console.log(currentAddress);
          // console.log(track.contractAddress);
          // console.log(creator);
          // console.log(vestingAddress);
          // console.log(vestingCard);
          // console.log(tokenIntId);
          // console.log(trackIntId);
          const R_Contract = rewards_contract()
          const hash = await R_Contract
            .createVestingSchedule(
              currentAddress,
              track.contractAddress, // tokenAddress
              creator, // in case of advertisement, creator is creator of

              vestingAddress, // adv address
              vestingCard, // adv token id

              tokenIntId, // tokenId
              trackIntId
            )
            .send({
              from: "0x204D6A18C33731c7D2592C00d64EB6248Bb8e32F",
              chainId: 80001,
              gas: 3000000,
              gasPrice: 10000000000,
            });
          console.log("hash", hash);
          setScheduleId(hash.events.ScheduleAdded.returnValues.vestingId);
        } catch (e) {
          console.log("check creating rewards record !", e);
        }
      }
    }
  };

  return (
    <>
      <div className="mp3-style">
        <ReactJkMusicPlayer
          getAudioInstance={(instance) => {
            setAudioInstance(instance);
          }}
          audioLists={playList}
          showDownload={false}
          clearPriorAudioLists={true}
          autoPlay={false}
          autoPlayInitLoadPlayList={true}
          onAudioPause={(e) => {
            audioPaused(e);
          }}
          onAudioPlay={(e) => {
            audioPlayed(e);
          }}
        />
      </div>
    </>
  );
}

export default MP3Player;
