import React, { useEffect, useState, useRef } from "react";

import ReactAudioPlayer from "react-audio-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import TransferModal from "../modals/transferModal";
import SaleModal from "../modals/saleModal";
import VestModal from "../modals/VestModal";
import ConfirmRemoveSale from "../modals/ConfirmRemoveSale";
import {
  transferIcon,
  demoIcon,
  playIcon,
  pauseIcon,
  cart,
  musicIcon,
} from "../../assets/img/index";
import { setIsPaused, setplayList } from "../../store/actions/action";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import { Card, Row, Col, Spinner, OverlayTrigger ,Tooltip} from "react-bootstrap";
import ShowPlaylist from "../modals/showPlayList";
/** For import wallet using its private key */
const Web3 = require("web3");
const HDWalletProvider = require("truffle-hdwallet-provider-privkey");
const RewardABI =
  require("../../connectors/contracts/RewardsContract.json").abi;

const NftCard = ({
  nft,
  transactor,
  userProvider,
  userTracks,
  nftIndex,
  vestingContract,
  contractAddress,
  isLibrary = false,
}) => {
  const isNftPlayed = useSelector((state) => state.getData.isNftPlayed);
  const playedNft = useSelector((state) => state.getData.playedNft);
  const isPaused = useSelector((state) => state.getData.isPaused);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState();
  const [showSaleModal, setShowSaleModal] = useState();
  const [showPlayListModal, setShowPlayListModal] = useState();
  const [showVestModal, setShowVestModal] = useState();
  const [selectedTransferData, setSelectedTransferData] = useState();
  const [selectedSaleData, setSelectedSaleData] = useState();
  const [selectedPlayList, setSelectedPlayList] = useState();
  
  const [scheduleId, setScheduleId] = useState();
  const [loaded, setLoaded] = useState(false);
  const [showIcons, setShowIcons] = useState(false);
  const [NFTAddress, setNFTAddress] = useState(false);

  const dispatch = useDispatch();
  const [showSongs, setShowSongs] = useState(false);
  const targetSongs = useRef(null);
  const signer = userProvider?.getSigner();

  const handleListForSale = (issale, nft) => {
    setSelectedSaleData(nft);
    if (issale) {
      setShowConfirmModal(true);
    } else {
      setShowSaleModal(true);
    }
  };

  const handleConfirm = () => {
    setShowSaleModal(true);
    setShowConfirmModal(false);
    setSelectedSaleData(selectedSaleData);
  };

  const pausePlayer = () => {
    dispatch(setIsPaused(isPaused === "true" ? "false" : "true"));
  };

  const getPlayList = () => {
    // console.log("userTracks", userTracks.get(nft.tokenID));
    // console.log("nfttttt", nft);
    const nftTracks = userTracks.get(nft.tokenID);
    // console.log("nft tracks from nftCard.js", nftTracks);
    // console.log("nft creator from nftCard.js", nft.NFTCreator);

    let tracksArr = [];
    nftTracks.forEach((track, index) => {
      let singleTrack = {
        name: track.name,
        singer: nft.name,
        cover: nft.image,
        musicSrc: track.source,
        contractAddress: track.contractAddress,
        nftTokenId: nft.tokenID,
        nftCreator: nft.NFTCreator,
        trackTokenId: track.tokenId._hex,
        trackId: track.id._hex,
        isNftSpecific: track.isNftSpecific,
        isAdvertisement:track.isAdvertisement,
        _advCollectionAddress:track._advContractAddress,
        _advCardId:track._advCardId,
        isDemo: false,
      };
      tracksArr.push(singleTrack);
      setNFTAddress(track.contractAddress);
    });
    // console.log(tracksArr);
    dispatch(setplayList(tracksArr));
  };

  const onImageLoaded = () => {
    setLoaded(true);
  };
  const handleShowPlaylist = () => {
    const nftTracks = userTracks.get(nft.tokenID);
    let tracksArr = [];
    nftTracks.forEach((track, index) => {
      let singleTrack = {
        name: track.name,
        singer: nft.name,
        cover: nft.image,
        musicSrc: track.source,
        contractAddress: track.contractAddress,
        nftTokenId: nft.tokenID,
        nftCreator: nft.NFTCreator,
        trackTokenId: track.tokenId._hex,
        trackId: track.id._hex,
        isNftSpecific: track.isNftSpecific,
        isDemo: false,
      };
      tracksArr.push(singleTrack);
      setSelectedPlayList(tracksArr);
      setShowPlayListModal(true);
    });
  };
  return (
    <div className="col-lg-3 col-md-4 col-sm-12 text-center mb-4">
      <Card
        className="nft-card h-100"
        onMouseEnter={(e) => {
          setShowIcons(true);
        }}
        onMouseLeave={(e) => {
          setShowIcons(false);
        }}
      >
        <div className="position-relative">
          <Avatar
            src={nft.image}
            onLoad={onImageLoaded}
            className="card-img img img-fluid border-radius-0"
          />
          {!loaded && (
            <div className="image-container-overlay">
              <Spinner animation="border" variant="danger" />
            </div>
          )}
          <img src={demoIcon} className="position-absolute demo-icon" />
        </div>
        <Card.Body>
          <Card.Title>
            {nft.name}
            <br />{" "}
            <p className="fz-14 text-muted mt-1">
              {userTracks.get(nft.tokenID).length} Songs
            </p>
          </Card.Title>
          <Card.Text className="mb-4 fz-14 text-muted">
            {nft.description}
          </Card.Text>
          <Row className="nft-card-icons">
            <Col
              className={`text-end ${showIcons ? "showIcons" : "hideIcons"}`}
            >
              <img
                src={transferIcon}
                alt="transfer"
                className="img img-fluid"
                onClick={() => {
                  setShowTransferModal(true);
                  setSelectedTransferData(nft);
                }}
              />
              {showTransferModal && (
                <TransferModal
                  show={showTransferModal}
                  handleCloseParent={(transtx) => {
                    setShowTransferModal(false);
                  }}
                  data={selectedTransferData}
                  transactor={transactor}
                  userProvider={userProvider}
                />
              )}
              {showConfirmModal && (
                <ConfirmRemoveSale
                  show={showConfirmModal}
                  handleCloseParent={() => {
                    setShowConfirmModal(false);
                  }}
                  data={selectedSaleData}
                  handleConfirm={() => handleConfirm()}
                />
              )}

              <img
                src={cart}
                alt="forSale"
                className="img img-fluid"
                onClick={() => {
                  handleListForSale(nft.sale, nft);
                }}
              />
              {showSaleModal && (
                <SaleModal
                  show={showSaleModal}
                  handleCloseParent={() => {
                    setShowSaleModal(false);
                  }}
                  data={selectedSaleData}
                  transactor={transactor}
                  userProvider={userProvider}
                />
              )}
              <FontAwesomeIcon icon={solid("gift")} onClick={() => {
                  setShowVestModal(true);
                }} className="fz-24 cursor-pointer" />
              {showVestModal && (
                <VestModal
                  show={showVestModal}
                  handleCloseParent={() => {
                    setShowVestModal(false);
                  }}
                  data={nft}
                  isLibrary={isLibrary}
                />
              )}
    {userTracks.get(nft.tokenID).length > 0 && (
            <>  <img
                  src={musicIcon}
                  alt="play"
                  className="img img-fluid"
                  onClick={handleShowPlaylist}
                 
                />
                {showPlayListModal &&selectedPlayList.length>0 && (
                  <ShowPlaylist
                    show={showPlayListModal}
                    handleCloseParent={() => {
                      setShowPlayListModal(false);
                    }}
                    data={selectedPlayList}
                    nftData={nft}
                  />
                )}</>
              )}
              {userTracks.get(nft.tokenID).length > 0 &&
                (isNftPlayed === "true" &&
                playedNft?.contractAddress === NFTAddress &&
                playedNft?.nftTokenId === nft.tokenID ? (
                  <img
                    src={pauseIcon}
                    alt="play"
                    className="img img-fluid"
                    onClick={() => {
                      pausePlayer();
                    }}
                  />
                ) : (
                  <img
                    src={playIcon}
                    alt="play"
                    className="img img-fluid"
                    onClick={() => {
                      getPlayList();
                    }}
                  />
                ))}
          
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};
export default NftCard;
