import React, { useEffect, useState, useCallback } from "react";
import InnerPagesLayout from "../component/InnerPagesLayout";
import { useSelector } from "react-redux";
import {Tabs,Tab} from "react-bootstrap";
import MyNFTs from "../component/MyNFTs";
import MyRewards from "../component/myRewards";


const MyRewardsPage = () => {
  
  const authedUser = useSelector((state) => state.getData.authedUser);

  return (
    <InnerPagesLayout>
      <div className="dg__service__area service-space bg--white">
        <div className="container">
          {/* video popup */}
          {/* {userMetadata === "" ? ( */}
          {authedUser === "false" ? (
            <div className="row justify-content-center mt-5">
              <div className="col-lg-12 col-md-12 col-sm-12 text-center mb-4">
                <h3 className="text-accent">you don't have access</h3>
                <p className="text-white">You should Login first</p>
              </div>
            </div>
          ) : (
            <div className='col-lg-12 profile-tabs py-5'>
              <MyRewards />
          </div>
          )}
        </div>
      </div>
    </InnerPagesLayout>
  );
};
export default MyRewardsPage;
