import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useUserAddress } from "eth-hooks";
import  UserVesting  from "../myRewards";
import MyRewards from "../myRewards";
import { Avatar } from "@mui/material";

const VestModal = ({
  show,
  handleCloseParent,
  data,
  isLibrary
}) => {
  const [showModal, setShowModal] = useState(show);
  const [address, setAddress] = useState(data?data.Wallet: "");

  const handleClose = () => {
    setShowModal(false);
    handleCloseParent("0");
  };


  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      scrollable={true}
      size='lg'
      backdrop='static'
      fullScreen={true}
      className="modal-fullscreen"
    >
      <Modal.Header closeButton>
        <Modal.Title><Avatar src={data.image} className="float-left mr-2"/>{data.name} {isLibrary?"Rewards":"Payouts"}  </Modal.Title>
      </Modal.Header>
      <Modal.Body>
     <MyRewards isCreation={isLibrary?false:true} isNftFilter={true} nftData={data}/>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn' onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default VestModal;
