import React from 'react';
import { TwitterIcon, TwitterShareButton } from 'react-share';
//shared component for twitter used in case of want to share NFT

const NFTTwitterShare = ({ item, isWithoutText = false }) => {
  //Light Gallery on icon click
// console.log(item)

  return (
    <>
      {!isWithoutText && (
        <span>Let other people know about it &nbsp;&nbsp; </span>
      )}{' '}
      <TwitterShareButton
        className='twitter-icon mfp-link'
        url={`${item.image}`}
        title={`I like "${item.name}" #NFT song`}
      >
        <TwitterIcon
          size={43}
          round
          iconFillColor='white'
          style={{ fill: 'black' }}
        />
      </TwitterShareButton>
    </>
  );
};
export default NFTTwitterShare;
