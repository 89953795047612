/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import { Contract } from "@ethersproject/contracts";
import { useEffect, useState } from "react";

import {
  NFT_OLD_ADDRESS,
  NFT_TRACKS_ADDRESS,
  PLAY_VESTING_CONTRACT,
  REWARDS_CONTRACT
} from "../constants";

/*
  ~ What it does? ~

  Loads your local contracts and gives options to read values from contracts
  or write transactions into them

  ~ How can I use? ~

  const readContracts = useContractLoader(localProvider) // or
  const writeContracts = useContractLoader(userProvider)

  ~ Features ~

  - localProvider enables reading values from contracts
  - userProvider enables writing transactions into contracts
  - Example of keeping track of "purpose" variable by loading contracts into readContracts
    and using ContractReader.js hook:
    const purpose = useContractReader(readContracts,"YourContract", "purpose")
  - Example of using setPurpose function from our contract and writing transactions by Transactor.js helper:
    tx( writeContracts.YourContract.setPurpose(newPurpose) )
*/

const getContractAddress = (name) => {
  switch (name) {
    case "Old":
      return NFT_OLD_ADDRESS;
    case "tracks":
      return NFT_TRACKS_ADDRESS;
    case "Rewards":
      return REWARDS_CONTRACT;    
    default:
      return false;
  }
};

export const loadContract = (contractName, signer, nftName) => {
  const contractAddress = getContractAddress(nftName);
  if(contractAddress) {
    //console.log("contract address : ", contractName);
    const newContract = new Contract(
      contractAddress,
      require(`../contracts/${contractName}.json`).abi,
      signer
    );
    try {
      newContract.bytecode =
        require(`../contracts/${contractName}.json`).bytecode;
    } catch (e) {
      console.log(e);
    }
    return newContract;
  }else{
    /** sent contract address */
    const newContract = new Contract(
      nftName,
      require(`../contracts/${contractName}.json`).abi,
      signer
    );
    try {
      newContract.bytecode =
        require(`../contracts/${contractName}.json`).bytecode;
    } catch (e) {
      console.log(e);
    }
    return newContract;
  }
};

export default function useContractLoader(providerOrSigner, nftName) {
  const [contracts, setContracts] = useState();
  useEffect(() => {
    async function loadContracts() {
      if (typeof providerOrSigner !== "undefined") {
        try {
          // we need to check to see if this providerOrSigner has a signer or not
          let signer;
          let accounts;
          if (
            providerOrSigner &&
            typeof providerOrSigner.listAccounts === "function"
          ) {
            accounts = await providerOrSigner.listAccounts();
          }

          if (accounts && accounts.length > 0) {
            signer = providerOrSigner.getSigner();
          } else {
            signer = providerOrSigner;
          }

          const contractList = require("../contracts/contracts");

          //console.log('contract list' , contractList);
           
          const newContracts = contractList.reduce(
            (accumulator, contractName) => {
              //console.log('contract reduce', contractName);
              accumulator[contractName] = loadContract(
                contractName,
                signer,
                nftName
              );
              return accumulator;
            },
            {}
          );
          setContracts(newContracts);
        } catch (e) {
          console.log("ERROR LOADING CONTRACTS!!", e);
        }
      }
    }
    loadContracts();
  }, [providerOrSigner, nftName]);
  //console.log('contracts details', contracts);
  return contracts;
}
