import React, { useState, Fragment, useEffect } from "react";
import ManageMyNftCardsTableSingle from "./manageMyNftCardsTableSingle";
import {
  TableBody,
  TableHead,
  Table,
  IconButton,
  TableCell,
  TableRow,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SvgMore from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
const ManageMyNftTableSingle = ({ item, index }) => {
  const [anchorEl, setAnchorEl] = useState();
  const [expand, setExpand] = useState(false);
  const handleExpand = () => {
    setExpand(!expand);
  };
  const handleOpenActions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
  };
  return (
    <Fragment>
      <TableRow key={index}>
        <TableCell className="market-symbol space-nowrap">
          <IconButton onClick={handleExpand} className="expand-collapse-icon">
            {expand ? <KeyboardArrowUpIcon /> : <SvgMore />}
          </IconButton>
        </TableCell>
        <TableCell>{item.title}</TableCell>
        <TableCell className="market-symbol space-nowrap">
          {item.desc}
        </TableCell>
        <TableCell className="market-prize space-nowrap">
          <span className="nobr"> {item.activeCards} </span>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="More"
            aria-owns={Boolean(anchorEl) ? "long-menu" : undefined}
            aria-haspopup="true"
            onClick={handleOpenActions}
          >
            <MoreVertIcon className="text-accent" />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseActions}
          >
            <MenuItem onClick={handleCloseActions}>
              <button className="btn">
                <FontAwesomeIcon icon={solid('music')} /> Manage Tracks
              </button></MenuItem>
              <MenuItem onClick={handleCloseActions}>
              <button className="btn">
                <FontAwesomeIcon icon={solid('plus-circle')} /> Mint New Card
              </button></MenuItem>
              <MenuItem onClick={handleCloseActions}>
              <button className="btn">
                <FontAwesomeIcon icon={solid('coins')} />Add Rewards
              </button></MenuItem>
              <MenuItem onClick={handleCloseActions}>
              <button className="btn">
                <FontAwesomeIcon icon={solid('shopping-cart')} /> List For Sale
              </button></MenuItem>
              <MenuItem onClick={handleCloseActions}>
              <button className="btn">
               <FontAwesomeIcon icon={solid('paper-plane')} /> Send
              </button></MenuItem>
              <MenuItem onClick={handleCloseActions}>
              <button className="btn">
                <FontAwesomeIcon icon={solid('envelope')} /> Email
              </button></MenuItem>
              <MenuItem onClick={handleCloseActions}>
              <button className="btn">
               <FontAwesomeIcon icon={brands('twitter')} /> Tweet
              </button>
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
      <Fragment>
        {expand && item.cards && item.cards.length > 0 && (
          <TableRow className="child__table ml-5">
            <TableCell colSpan="6" className="child__table__main">
              <div className="row market__table">
                <div className="col-lg-12 table-responsive">
                  <Table className="mkt__pre__list">
                    <TableHead>
                      <TableRow className="sub__table">
                        <TableCell className="market-volume">&nbsp;</TableCell>
                        <TableCell className="market-volume">
                          Card Number
                        </TableCell>
                        <TableCell className="market-volume">
                          Owner Wallet
                        </TableCell>
                        <TableCell className="market-volume">
                          Current Rewards
                        </TableCell>
                        <TableCell className="market-volume"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item.cards &&
                        item.cards.map((uCard, index) => (
                          <ManageMyNftCardsTableSingle
                            key={index}
                            uCard={uCard}
                            index={index}
                          />
                        ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </TableCell>
          </TableRow>
        )}
        {item.cards && item.cards.length === 0 && expand && (
          <TableRow className="child__table">
            <TableCell colSpan="6">
              <span>No Data To Show</span>
            </TableCell>
          </TableRow>
        )}
      </Fragment>
    </Fragment>
  );
};

export default ManageMyNftTableSingle;
