import React, { useState, useCallback, useEffect } from "react";
import InnerPagesLayout from "../component/InnerPagesLayout";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import { Spinner } from "react-bootstrap";
import ImageUploader from "react-images-upload";
import AddSound from "../component/add-sound/AddSound";
import { NFTStorage } from "nft.storage";

import { Web3Provider } from "@ethersproject/providers";
import { BigNumber } from "@ethersproject/bignumber";
import { useGasPrice } from "eth-hooks";
import useContractLoader from "../connectors/hooks/ContractLoader";
import useUserProvider from "../connectors/hooks/UserProvider";
import localProvider from "../connectors/helpers/Provider";
import Transactor from "../connectors/helpers/Transactor";
import {
  NFT_STORAGE_KEY,
  DEFAULT_CONTRACT_NAME,
  NETWORKS,
} from "../connectors/constants";
import { useSelector } from "react-redux";

import { ethers } from "ethers";
import StepWizard from "react-step-wizard";
import { Row, Col, Button, FormGroup } from "react-bootstrap";
import { Stepper, Step } from "react-form-stepper";
import AddNFTData from "../component/add-nft/addNewNft"
import LoadTokens from "../component/add-nft/LoadTokens"
import AddTracks from "../component/add-nft/addTracks"
function makeGatewayURL(ipfsURI) {
  return ipfsURI.replace(/^ipfs:\/\//, "https://dweb.link/ipfs/");
}

async function fetchIPFSJSON(ipfsURI) {
  const resp = await fetch("https://dweb.link/ipfs/" + ipfsURI);
  return resp.json();
}

async function getNFTImage(tokenMetaUri) {
  const metadata = await fetchIPFSJSON(tokenMetaUri);

  if (metadata.image) {
    metadata.image = makeGatewayURL(metadata.image);
  }

  return metadata.image;
}






function AddNft() {


  const [stepWizard, setStepWizard] = useState(null);
  const [nft, setNft] = useState({});
  const [activeStep, setActiveStep] = useState(0);

  const assignStepWizard = (instance) => {
    setStepWizard(instance);
  };

  const assignNft = (val) => {
    // console.log("parent receive user callback");
    // console.log(val);
    setNft(val);
  };

  const handleStepChange = (e) => {
    // console.log("step change");
    // console.log(e);
    setActiveStep(e.activeStep - 1);
  };

  const handleComplete = () => {
    alert("You r done. TQ");
  };


  return (
    <InnerPagesLayout>
      <div className="container mt-5">
        <AnimatedOnScroll animationIn="fadeInUp" isVisible={true}>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-7">
              <div className="row">
                <div className="col">
                  <Stepper activeStep={activeStep}>
                    <Step label="Add NFT" />
                    <Step label="Load Tokens" />
                    <Step label="Add Tracks" />
                  </Stepper>
                  {/* NOTE: IMPORTANT !! StepWizard must contains at least 2 children components, else got error */}
                  <StepWizard
                    instance={assignStepWizard}
                    onStepChange={handleStepChange}
                  >
                    <AddNFTData ApplyNftData={assignNft}/>
                   <LoadTokens nft={nft} />
                  <AddTracks nft={nft} completeCallback={handleComplete} />
                  </StepWizard>
                </div>
              </div>


             
            </div>
          </div>
        </AnimatedOnScroll>
      </div>
    </InnerPagesLayout>
  );
}
export default AddNft;
