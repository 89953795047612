import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { parseUnits } from "@ethersproject/units";
import CurrencyFormat from "react-currency-format";

const ConfirmRemoveSale = ({ show, handleCloseParent, data, handleConfirm }) => {
  const [showModal, setShowModal] = useState(show);

  const handleClose = () => {
    setShowModal(false);
    handleCloseParent();
  };


  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      scrollable={true}
      size='lg'
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title>List {data.name} for Sale</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='contact-form'>
          <div className='row'>
            <div className='col-9'>
        <h4>This nft is already listed for sale</h4>
        <h6>Are you sure you want to cancel it?</h6>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn' onClick={handleClose}>
          No
        </button>
        <button className='btn color-accent' onClick={handleConfirm}>
          Yes
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmRemoveSale;
