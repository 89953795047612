import React, { useState } from "react";
import {TableCell,TableRow,IconButton,MenuItem,Menu} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';

import MoreVertIcon from "@mui/icons-material/MoreVert";

const ManageMyNftCardsTableSingle = ({ uCard, index }) => {
  const [anchorEl, setAnchorEl] = useState();


  const handleOpenActions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
  };
  return (
    <TableRow key={index}>
       <TableCell className="market-volume space-nowrap">
        <span className="nobr"></span>
      </TableCell>
      <TableCell className="market-volume space-nowrap">
        <span className="nobr">{uCard.cardNumber}</span>
      </TableCell>
      <TableCell className="market-volume space-nowrap">
        <span className="nobr">{uCard.ownerWallet}</span>
      </TableCell>
      <TableCell className="market-volume space-nowrap">
        <span className="nobr">{uCard.currentRewards}</span>
      </TableCell>
      <TableCell className="market-volume space-nowrap">
          <IconButton
            aria-label="More"
            aria-owns={Boolean(anchorEl) ? "long-menu" : undefined}
            aria-haspopup="true"
            onClick={handleOpenActions}
          >
            <MoreVertIcon className="text-accent" />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseActions}
          >
              <MenuItem onClick={handleCloseActions}>
              <button className="btn">
              <FontAwesomeIcon icon={solid('coins')} />Add Rewards
              </button></MenuItem>
              <MenuItem onClick={handleCloseActions}>
              <button className="btn">
                <FontAwesomeIcon icon={solid('shopping-cart')} />List For Sale
              </button></MenuItem>
              <MenuItem onClick={handleCloseActions}>
              <button className="btn">
                <FontAwesomeIcon icon={solid('paper-plane')} /> Send
              </button></MenuItem>
              <MenuItem onClick={handleCloseActions}>
              <button className="btn">
               <FontAwesomeIcon icon={solid('envelope')} /> Email
              </button></MenuItem>
              <MenuItem onClick={handleCloseActions}>
              <button className="btn">
              <FontAwesomeIcon icon={brands('twitter')} /> Tweet
              </button>
            </MenuItem>
          </Menu>
        
      </TableCell>
    </TableRow>
  );
};

export default ManageMyNftCardsTableSingle;
