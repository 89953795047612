import React from "react";
import InnerPagesLayout from "../component/InnerPagesLayout";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import { about } from "../assets/img";


const MusicBin=()=> {
  return (
<InnerPagesLayout>
<section id="section_12" className="about_section  mb-5">
            <div className="container">
        

            
              <div className="row align-items-center mb-5">
                <div className="col-lg-6 col-md-6 col-xs-12">
                <div className="section_head_widget animatedParent text-left ">
                    <AnimatedOnScroll animationIn="fadeInDown" isVisible={true}>

                      <h4>Welcome to the music bin! </h4>
                    </AnimatedOnScroll>
                  </div>
                  <div className="text_widget">
                    <p>
                    This is where are you going to see albums offered by other users. You can check the rewards still remaining on each of the music MFT and decide whether you would like to buy it or not.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-xs-12 text-right">
                <AnimatedOnScroll animationIn="fadeInRight" isVisible={true}>
                  <img src={about} className="img img-fluid"/>
                  </AnimatedOnScroll>
                </div>
              </div>
            </div>
          </section>
  </InnerPagesLayout>
  );
}
export default MusicBin;
