import React, { useState , useEffect , useCallback } from "react";
import AddTrackModal from "../component/addTrack";
import InnerPagesLayout from "../component/InnerPagesLayout";
import TracksTable from "../component/tracksTable";

import { Web3Provider } from "@ethersproject/providers";
import { Alert, Spinner } from "react-bootstrap";
import useContractLoader from "../connectors/hooks/ContractLoader";
import useUserProvider from "../connectors/hooks/UserProvider";
import localProvider from "../connectors/helpers/Provider";
import { useSelector } from "react-redux";
import { ethers } from "ethers";

const ManageTracks = () => {

  const authedUser = useSelector((state) => state.getData.authedUser);

  const [loading, setLoading] = useState(true);
  

  const [injectedProvider, setInjectedProvider] = useState();
  const [TracksContract, setTracksContract] = useState(); //tracks contract state

  const userProvider = useUserProvider(injectedProvider, localProvider);
  const tracksContracts = useContractLoader(userProvider, "tracks"); //Tracks contract loader
  //console.log("tracksContracts", tracksContracts)

  //console.log("userProvider", userProvider)
  const signer = userProvider?.getSigner();

  const [allTracks, setAllTracks] = useState();
  const [showAddTrack, setShowAddTrack] = useState(false);


  const loadWeb3Modal = useCallback(async () => {
    setInjectedProvider(new Web3Provider(window.ethereum));
  }, [setInjectedProvider]);

  useEffect(() => {
    loadWeb3Modal();
  }, [loadWeb3Modal]);


  const getAllTracks = useCallback(
    async (contract) => {
      // console.log("here in get all tracks")
      const tracksMetaData = [];
      const currentTracks = await contract.getAllTracks(); /** call contract to get all nfts */
      
      // console.log("current " , typeof currentTracks, currentTracks)
      
      for (var track of currentTracks) {
        tracksMetaData.push(track);
      } 

      return tracksMetaData;
    },
    []
  );


  const loadTracksData = useCallback(async () => {
    
    /** Load NFTS' contract */
    const TracksContract = tracksContracts? tracksContracts['PaybeatsTracks'] : "";
    TracksContract && setTracksContract(TracksContract);
    // console.log("TracksContract: ", TracksContract);

    /** Get All IDs' Of The Current NFTs Tokens */
    const getTracks = TracksContract && (await getAllTracks(TracksContract));
    // console.log("getTracks: ", getTracks);
    const TracksMeta = getTracks && [...getTracks];

    /** Set State To Stop Spinner And Display In UI */
    !!TracksMeta && setAllTracks(TracksMeta);
    !!TracksMeta && setLoading(false);
    
    // console.log("Tracks", TracksMeta);
    
    return allTracks;

  }, [tracksContracts, getAllTracks, allTracks]);  /** These props to watch incase changes will fire the callback */

  useEffect(() => {
    if (!allTracks) {
      loadTracksData();
    }
  }, [loadTracksData, allTracks]);

  const addNewTrack = async (newTrack, isAddToNft) => {

    try {
      const RightReward = ethers.utils.parseEther(newTrack.rewardsRate.toString());
      // console.log("track source", newTrack.source);
      let adv;
      if(newTrack.isAd){
        adv = true;
      }else{
        adv = false;
      }
      if(isAddToNft){
        const trackId = await TracksContract._addTrackToSpecificCard(
          newTrack.nftContract, newTrack.tokenId, newTrack.name, newTrack.image, newTrack.description , 
          newTrack.source, RightReward, newTrack.trackDuration, adv
        );
        if (trackId){
          alert('Track Added Successfully')
          // console.log("track id" , trackId)
        } else {
          alert('Error Adding Track')
        }
      }else{
        const trackId = await TracksContract._addTrackToContract(
          newTrack.nftContract, newTrack.name, newTrack.image, newTrack.description , 
          newTrack.source, RightReward, newTrack.trackDuration, adv
        );
        if (trackId){
          alert('Track Added Successfully')
          // console.log("track id" , trackId)
        } else {
          alert('Error Adding Track')
        }
      }
    } catch (error) {
        console.log("error")
    }
    

  }

  return (
    <InnerPagesLayout>
      <div className="container mt-3 pl-0">
        <button
          className="btn btn-warning btn-lg"
          onClick={() => {
            setShowAddTrack(true);
          }}
        >
          Add New Track
        </button>
      </div>

      {!!loading ? (
            <div className="row justify-content-center mt-5">
              <div className="col-lg-12 col-md-12 col-sm-12 text-center mb-4">
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                  className="text-accent"
                />
              </div>
            </div>
          ) : (
            <>
            {allTracks && allTracks.length > 0 ? 
              (
                <TracksTable tracks={allTracks}/>
              ):(
                <div className="row justify-content-center mt-5">
                   <h2>No Track To Show</h2>
                </div>
              )
            }
            </>
            
      )}

      {showAddTrack && (
        <AddTrackModal
          show={showAddTrack}
          handleCloseParent={() => {
            setShowAddTrack(false);
          }}
          handleAddParent={(newTrack, isAddToNft) => {
            // console.log("Handle Add ", newTrack)
            setShowAddTrack(false);
            addNewTrack(newTrack, isAddToNft);
          }}
        />
      )}
    </InnerPagesLayout>
  );
};
export default ManageTracks;
