import { ethers } from "ethers";

const Web3 = require("web3");
const HDWalletProvider = require("truffle-hdwallet-provider-privkey");

const RewardABI =
  require("../connectors/contracts/RewardsContract.json").abi;

  const maticNode = process.env.REACT_APP_MUMBAI_RPC_URL;
  const custodian = {
    public: "0x204D6A18C33731c7D2592C00d64EB6248Bb8e32F",
    private: [
      "4ff4e4322994486d89f53839f19fa7be7488745c3b580bf8f799eadb6107937d",
    ],
  };
  const RewardsAddress = "0x8f8Ce1a959e9Fb8f33b55e2f8ACA6BE57021616B";





export const web3_rewards_instance = ()=>{
    const provider = new HDWalletProvider(custodian.private, maticNode);
    const web3 = new Web3(provider.engine);
    return web3
}

export const rewards_contract = ()=>{
    const provider = new HDWalletProvider(custodian.private, maticNode);
    //const provider =  new ethers.providers.JsonRpcProvider(maticNode)
    
    //
    const web3 = new Web3(provider);
    debugger
    const rewards_contract = new web3.eth.Contract(RewardABI, RewardsAddress)
      .methods;

      return rewards_contract
}