import React, { useEffect, useState, useCallback } from "react";
import { Spinner } from "react-bootstrap";
import { useUserAddress } from "eth-hooks";
import useGasPrice from "../../connectors/hooks/GasPrice";

import { DEFAULT_CONTRACT_NAME, NETWORKS } from "../../connectors/constants";
import useContractLoader from "../../connectors/hooks/ContractLoader";
import useUserProvider from "../../connectors/hooks/UserProvider";
import localProvider from "../../connectors/helpers/Provider";
import Transactor from "../../connectors/helpers/Transactor";

import { Web3Provider } from "@ethersproject/providers";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ethers } from "ethers";
import { rewards_contract } from "../../utils/rewards-contract";
/** For import wallet using its private key */
// const Web3 = require("web3");
// const HDWalletProvider = require("truffle-hdwallet-provider-privkey");
// const RewardABI =
//   require("../../connectors/contracts/RewardsContract.json").abi;

const MyRewards = ({
  isCreation = false,
  isNftFilter = false,
  nftData = {},
}) => {
  const [vestingData, setVestingData] = useState();
  const [loading, setLoading] = useState(true);

  const [injectedProvider, setInjectedProvider] = useState();
  const [playVestingContract, setPlayVestingContract] = useState();

  const userProvider = useUserProvider(injectedProvider, localProvider);
  const playVestingContracts = useContractLoader(userProvider, "Rewards");
  const userAddress = useUserAddress(userProvider);
  //console.log("playVestingContracts", playVestingContracts);

  // const signer = userProvider.getSigner();

  const gasPrice = useGasPrice(NETWORKS.mumbai, "fast");
  console.log("injectedProvider", injectedProvider);

  const loadWeb3Modal = useCallback(async () => {
    setInjectedProvider(new Web3Provider(window.ethereum));
  }, [setInjectedProvider]);

  useEffect(() => {
    loadWeb3Modal();
  }, [loadWeb3Modal]);

  // const maticNode = process.env.REACT_APP_MUMBAI_RPC_URL;
  // const custodian = {
  //   public: "0x204D6A18C33731c7D2592C00d64EB6248Bb8e32F",
  //   private: [
  //     "4ff4e4322994486d89f53839f19fa7be7488745c3b580bf8f799eadb6107937d",
  //   ],
  // };
  // const RewardsAddress = "0x8f8Ce1a959e9Fb8f33b55e2f8ACA6BE57021616B";

  // const provider = new HDWalletProvider(custodian.private, maticNode);
  // // console.log('provider', provider);
  // const web3 = new Web3(provider.engine);
  // const rewards_contract = new web3.eth.Contract(RewardABI, RewardsAddress)
  //   .methods;

  const claimVesting = async (VestingSchedule) => {
    try {
      debugger
      // console.log(
      //   'VestingSchedule.vestingScheduleId',
      //   VestingSchedule.vestingScheduleId
      // );
      const R_Contract = rewards_contract()
      debugger
      const tx = await R_Contract
        .release(VestingSchedule.vestingScheduleId)
        .send({
          from: "0x204D6A18C33731c7D2592C00d64EB6248Bb8e32F",
          chainId: 80001,
          gas: 3000000,
          gasPrice: 10000000000,
        });
      console.log("claim", tx);
    } catch (e) {
      console.log(e);
    }
  };

  const getUserVesting = useCallback(
    async (currentAddress, contract) => {
      let userVestingData = [];
      //let filteredVesting = [];
      if (isCreation) {
        const creatorVestingSchedules_a = await contract.getCreatorSchedules(
          currentAddress
        );
        // console.log("creatorVestingSchedules_a", creatorVestingSchedules_a);

        //const advertiserSchedules =
        //  await contract.getAdvSchedules(currentAddress);
        //console.log("advertiserSchedules", advertiserSchedules);

        //let creatorVestingSchedules = creatorVestingSchedules_a.concat(advertiserSchedules);
        let creatorVestingSchedules = creatorVestingSchedules_a;
        creatorVestingSchedules = creatorVestingSchedules.filter(
          (k) => k.initialized == true
        );
        // console.log('currentAddress', currentAddress);
        // console.log("creatorVestingSchedule", creatorVestingSchedules);

        for (let i = 0; i < creatorVestingSchedules.length; i++) {
          //console.log("vestingSChdeulude ", vestingSchedule)
          let vestingSchedule = creatorVestingSchedules[i];
          userVestingData.push({
            tokenId: parseInt(vestingSchedule.tokenId._hex, 16),
            //trackId: parseInt(vestingSchedule.trackId._hex, 16),
            beneficiary: vestingSchedule.beneficiary,
            //vestingScheduleId: vestingSchedule.,
            maxAmount:
              parseInt(vestingSchedule.amountTotal._hex, 16) / 10 ** 18,
            nftContractAddress: vestingSchedule._nftContract,
            nftTokenId: parseInt(vestingSchedule.tokenId._hex, 16),
            released: parseInt(vestingSchedule.released._hex, 16) / 10 ** 18,
            Nft: "Classic",
          });
        }
        // console.log('userVestong', userVestingData);

        return userVestingData;
      } else {
        const vestingCountHex =
          await contract.getVestingSchedulesCountByBeneficiary(currentAddress);
        const vestingCount = parseInt(vestingCountHex._hex, 16);
        // console.log(isCreation);

        for (let i = 0; i < vestingCount; i++) {
          const IthVestingData =
            await contract.getVestingScheduleByAddressAndIndex(
              currentAddress,
              i
            );
          // console.log('IthVestingData', IthVestingData);
          // console.log('IthVestingData.beneficiary', IthVestingData.beneficiary);
          const IthVestingScheduleId =
            await contract.computeVestingScheduleIdForAddressAndIndex(
              currentAddress,
              i
            );
          //console.log("ith vesting id ", IthVestingScheduleId);
          let IthVestingScheduleReleasableAmount = 0;
          try {
            IthVestingScheduleReleasableAmount =
              await contract.computeReleasableAmount(IthVestingScheduleId);
          } catch (e) {
            console.log(e);
          }
          //console.log("IthVestingScheduleReleasableAmount", parseInt(IthVestingScheduleReleasableAmount._hex,16));
          let realAmount =
            parseInt(IthVestingScheduleReleasableAmount._hex, 16) / 10 ** 18 ||
            0;
          if (realAmount != 0) {
            //console.log(realAmount);
            userVestingData.push({
              tokenId: parseInt(IthVestingData.tokenId._hex, 16),
              //trackId: parseInt(IthVestingData.trackId._hex, 16),
              vestingScheduleId: IthVestingScheduleId,
              releasableAmount: realAmount,
              maxAmount:
                parseInt(IthVestingData.amountTotal._hex, 16) / 10 ** 18,
              nftContractAddress: IthVestingData._nftContract,
              nftTokenId: parseInt(IthVestingData.tokenId._hex, 16),
              Nft: "Classic",
            });
          }
        }

        return userVestingData;
      }
    },
    [isCreation]
  );

  const loadVestingContract = useCallback(async () => {
    const playVestingContract = playVestingContracts
      ? playVestingContracts["RewardsContract"]
      : "";
    playVestingContract && setPlayVestingContract(playVestingContract);

    // const currentAddress = await signer.getAddress();
    //console.log("i'm here, and this is Vesting", playVestingContract);

    const getUserData =
      playVestingContract&& playVestingContract!==""&&
      userAddress&&userAddress!=="" &&
      (await getUserVesting(userAddress, playVestingContract));
    //console.log("getUserData", getUserData);
    if (!!playVestingContract&&getUserData) {
      if (isNftFilter) {
        const filteredVest = getUserData.filter(
          (vest) =>
            vest.nftContractAddress.toLowerCase() ===
              nftData.contractAddress.toLowerCase() &&
            vest.nftTokenId.toString() === nftData.tokenID.toString()
        );
        setVestingData(filteredVest);
        setLoading(false);
      } else {
        setVestingData(getUserData);
        setLoading(false);
      }
    }
    //console.log('loading ', loading);
    return vestingData;
  }, [
    playVestingContracts,
    getUserVesting,
    vestingData,
    userAddress,
    isNftFilter,
    nftData.contractAddress,
    nftData.tokenID,
  ]); /** These props to watch incase changes will fire the callback */

  useEffect(() => {
    if (!vestingData) {
      loadVestingContract();
    } else {
      console.log(vestingData);
    }
  }, [loadVestingContract, vestingData]);

  return (
    <>
      <div className="container">
        {/* video popup */}

        {loading ? (
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 col-sm-12 text-center mb-4">
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                className="text-accent"
              />
            </div>
          </div>
        ) : (
          <>
            <div className="row market__table">
              <div className="col-lg-12 table  table-responsive tableFixHead ">
                {vestingData && vestingData.length > 0 ? (
                  <table className="mkt__pre__list w-100 ">
                    <thead>
                      {/**
                        tokenId: parseInt(IthVestingData.tokenId._hex,16),
                        releasableAmount: parseInt(IthVestingScheduleReleasableAmount.tokenId._hex,16),
                        maxAmount: parseInt(IthVestingData.amountTotal._hex,16),
                        Nft: "Classic"
                     */}
                      <tr>
                        <th className="market-symbol">Max Amount</th>
                        <th className="market-symbol">
                          {isCreation ? "Payouts" : "Rewards"} (
                          {vestingData &&
                            vestingData.length > 0 &&
                            vestingData
                              .map((a) =>
                                isCreation ? a.released : a.releasableAmount
                              )
                              .reduce(function (a, b) {
                                return a + b;
                              })}
                          )
                        </th>
                        <th className="market-prize">
                          <span className="nobr">Category</span>
                        </th>

                        {isCreation && <th>Beneficiary</th>}
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {vestingData && vestingData.length > 0 && !isCreation && (
                        <tr>
                          <td></td>
                          <td className="market-s/ymbol space-nowrap"></td>
                          <td className="market-prize space-nowrap"></td>
                          {/* <td></td> */}

                          <td className="market-volume">
                            <button className="btn btn-danger btn-lg ">
                              Claim All
                            </button>
                          </td>
                        </tr>
                      )}
                      {vestingData &&
                        vestingData.map((d) => (
                          <tr key={d.id}>
                            <td className="market-s/ymbol space-nowrap">
                              {d.maxAmount}
                            </td>
                            <td className="market-s/ymbol space-nowrap">
                              {isCreation ? d.released : d.releasableAmount}
                            </td>
                            <td className="market-prize space-nowrap">
                              <span className="nobr">{d.Nft} </span>
                            </td>
                            {isCreation && (
                              <td>
                                <a
                                  href={`https://mumbai.polygonscan.com/address/${d.beneficiary}`}
                                  rel="noreferrer noopener"
                                  target="_blank"
                                >
                                  {d.beneficiary}
                                </a>
                              </td>
                            )}

                            {!isCreation && (
                              <td className="market-volume">
                                <button
                                  onClick={() => {
                                    claimVesting(d);
                                  }}
                                  className="btn btn-danger btn-lg "
                                >
                                  Claim
                                </button>
                              </td>
                            )}
                          </tr>
                        ))}

                      {vestingData && vestingData.length > 0 && !isCreation && (
                        <tr>
                          <td></td>
                          <td className="market-s/ymbol space-nowrap"></td>
                          <td className="market-prize space-nowrap"></td>
                          {/* <td></td> */}

                          <td className="market-volume">
                            <button className="btn btn-danger btn-lg ">
                              Claim All
                            </button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                ) : (
                  <div className="text-center text-accent">
                    You have no {isCreation ? "payouts" : "rewards"}{" "}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MyRewards;
