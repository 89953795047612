import React, { useState } from "react";

import { mainBG, Kulthum, Hafez, n1, n2, n3 } from "../assets/img/index";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import InnerPagesLayout from "../component/InnerPagesLayout";
import HomeNFTCard from "../component/homeNFTCard";
import Carousel from "react-elastic-carousel";
const Home = () => {
  const s3Link =
    "https://paybeats-tracks.s3.us-east-2.amazonaws.com/paybeats-tracks/PayBeats+Songs/";
  const latestAlbums = [
    {
      image:
        "https://ingilizcesarkilar.files.wordpress.com/2012/01/adele.jpg?w=584",
      name: "Adele",
      description:
        "Adele Laurie born 5 May 1988 is an English singer and songwriter. She is one of the world's best-selling music artists, with sales of over 120 million records.",
      id: 1,
      type: 1,
      tracks: [
        {
          name: "Skyfall",
          source: `${s3Link}Adele/Adele+-+Skyfall.mp3`,
        },
        {
          name: "Hello",
          source: `${s3Link}Adele/Adele+-+Hello.mp3`,
        },
        {
          name: "Easy On Me",
          source: `${s3Link}Adele/Adele+-+Easy+On+Me.mp3`,
        },
        {
          name: "Someone Like You",
          source: `${s3Link}Adele/Adele+-+Someone+Like+You.mp3`,
        },
      ],
    },
    {
      image:
        "https://i.ebayimg.com/images/g/2WwAAOSwSsxhDx0-/s-l300.jpg",
      name: "Ariana Grande",
      description:
        "Ariana Grande-Butera born June 26, 1993 is an American singer, songwriter, and actress. She has received numerous accolades, including two Grammy Awards.",
      id: 2,
      type: 1,
      tracks: [
        {
          name: "7 Rings",
          source: `${s3Link}Ariana+Grande/Ariana+Grande+-+7+rings.mp3`,
        },
        {
          name: "No tears left to Cry",
          source: `${s3Link}Ariana+Grande/Ariana+Grande+-+no+tears+left+to+cry.mp3`,
        },
        {
          name: "Positions",
          source: `${s3Link}Ariana+Grande/Ariana+Grande+-+positions.mp3`,
        },
        {
          name: "Side To Side",
          source: `${s3Link}Ariana+Grande/Ariana+Grande+Side+To+Side+ft.+Nicki+Minaj.mp3`,
        },
      ],
    },
    {
      image:
        "https://img4.bdbphotos.com/images/230x300/p/9/p9ozsuaozo7e7zeo.jpg?skj2io4l",
      name: "P!nk",
      description:
        "Alecia Beth Moore (born September 8, 1979), known professionally as Pink, is an American singer and songwriter.With her second studio album Missundaztood (2001).",
      id: 3,
      type: 1,
      tracks: [
        {
          name: "A Million Dreams",
          source: `${s3Link}P_nk/P_nk+-+A+Million+Dreams.mp3`,
        },
        {
          name: " All I Know So Far",
          source: `${s3Link}P_nk/P_NK+-+All+I+Know+So+Far.mp3`,
        },
        {
          name: "Just Give Me A Reason",
          source: `${s3Link}P_nk/P_nk+-+Just+Give+Me+A+Reason.mp3`,
        },
        {
          name: "So What",
          source: `${s3Link}P_nk/P_nk+-+So+What.mp3`,
        },
        {
          name: "Try",
          source: `${s3Link}P_nk/P_nk+-+Try.mp3`,
        },
        {
          name: "Willow Sage Hart",
          source: `${s3Link}P_nk/P_nk_+Willow+Sage+Hart+.mp3`,
        },
      ],
    },
    {
      image:
        "https://img4.bdbphotos.com/images/230x300/b/1/b1ed6v1wd6xxvd16.jpg?skj2io4l",
      name: "Eric church",
      description:
        "Kenneth Eric Church (born May 3, 1977) is an American singer-songwriter.",
      id: 4,
      type: 2,
      tracks: [
        {
          name: "Misunderstood",
          source: `${s3Link}Eric+Church/Eric+Church+-+Mr.+Misunderstood.mp3`,
        },
        {
          name: "Round Here Buzz",
          source: `${s3Link}Eric+Church/Eric+Church+-+Round+Here+Buzz.mp3`,
        },
        {
          name: "Smoke A Little Smoke",
          source: `${s3Link}Eric+Church/Eric+Church+-+Smoke+A+Little+Smoke.mp3`,
        },
      ],
    },
    {
      image:
        "https://img4.bdbphotos.com/images/230x300/t/d/tdxty1kjtx1atkxj.jpg?skj2io4l",
      name: "Chris Stapleton",
      description:
        "Christopher Alvin Stapleton born April 15, 1978 is an American singer-songwriter, guitarist, and record producer. As of 2018, Stapleton has amassed credits writing and co-writing over 170 songs.",
      id: 5,
      type: 2,
      tracks: [
        {
          name: "Broken Halos",
          source: `${s3Link}Chris+Stapleton/Chris+Stapleton+-+Broken+Halos.mp3`,
        },
        {
          name: "Millionaire",
          source: `${s3Link}Chris+Stapleton/Chris+Stapleton+-+Millionaire.mp3`,
        },
        {
          name: "Tennessee Whiskey",
          source: `${s3Link}Chris+Stapleton/Chris+Stapleton+-+Tennessee+Whiskey.mp3`,
        },
      ],
    },
    {
      image:
        "https://media-cldnry.s-nbcnews.com/image/upload/t_fit-1500w,f_auto,q_auto:best/streams/2012/September/120905/521366-2012-07-12t221343z_2_cbre86b1pn200_rtroptp_3_music-hankwilliamsjr.jpg",
      name: "Hank Williams Jr.",
      description:
        "Randall Hank Williams (born May 26, 1949), known professionally as Hank Williams Jr. or Bocephus, is an American singer-songwriter and musician.",
      id: 6,
      type: 2,
      tracks: [
        {
          name: "Family tradition",
          source: `${s3Link}Hank+Wiliams+Jr/Hank+Williams+jr+-+Family tradition.mp3`,
        },
        {
          name: "A Country Boy Can Survive",
          source: `${s3Link}Hank+Wiliams+Jr/Hank+Williams_+Jr.+-+_A+Country+Boy+Can+Survive.mp3`,
        },
        {
          name: "If Heaven Ain't A Lot Like Dixie",
          source: `${s3Link}Hank+Wiliams+Jr/If+Heaven+Ain_t+A+Lot+Like+Dixie.mp3`,
        },
      ],
    },
    {
      image:
        "https://img4.bdbphotos.com/images/230x300/4/0/40109yunuri0y0u9.jpg?skj2io4l",
      name: "Johnny Cash",
      description:
        "John R. Cash (born J. R. Cash; February 26, 1932 – September 12, 2003) was an American singer, songwriter, musician, and actor. Much of Cash's music contained themes of sorro.",
      id: 7,
      type: 2,
      tracks: [
        {
          name: "I Walk the Line",
          source: `${s3Link}Johnney+Cash/I+Walk+the+Line.mp3`,
        },
        {
          name: "Hurt",
          source: `${s3Link}Johnney+Cash/Johnny+Cash+-+Hurt.mp3`,
        },
        {
          name: "Ring of Fire",
          source: `${s3Link}Johnney+Cash/Ring+of+Fire.mp3`,
        },
      ],
    },
    {
      image:
        "https://img4.bdbphotos.com/images/230x300/9/6/9660cdurafsn.jpg?skj2io4l",
      name: "Dr. Dre",
      description:
        "Andre Romelle Young (born February 18, 1965), known professionally as Dr. Dre, is an American rapper, record producer.",
      id: 8,
      type: 3,
      tracks: [
        {
          name: "The Next Episode",
          source: `${s3Link}Dr.+Dre/Dr.+Dre+-+The+Next+Episode.mp3`,
        },
        {
          name: "Still Dre",
          source: `${s3Link}Dr.+Dre/Dr.+Dre+feat.+Snoop Dogg+-+Still+Dre.mp3`,
        },
      ],
    },
    {
      image:
        "https://i.ebayimg.com/images/g/FR8AAOSwymxVK0qM/s-l300.jpg",
      name: "Eminem",
      description:
        "Marshall Bruce Mathers III (born October 17, 1972), known professionally as Eminem, is an American rapper, songwriter, and record producer. Eminem is among the best-selling music artists of all time.",
      id: 9,
      type: 3,
      tracks: [
        {
          name: "Love The Way You Lie",
          source: `${s3Link}Eminem/Eminem+-+Love+The+Way+You+Lie.mp3`,
        },
        {
          name: "Not Afraid",
          source: `${s3Link}Eminem/Eminem+-+Not+Afraid.mp3`,
        },
      ],
    },
    {
      image:
        "https://img4.bdbphotos.com/images/230x300/n/u/nuzfe12um4eq2eu1.jpg?skj2io4l",
      name: "Kool & The Gang",
      description:
        "ool & The Gang Is An American R&B/Funk Band Formed In Jersey City, New Jersey, In 1964.",
      id: 10,
      type: 4,
      tracks: [
        {
          name: "Celebration",
          source: `${s3Link}Kool/Kool+_+The+Gang+-+Celebration.mp3`,
        },
        {
          name: "Emergency",
          source: `${s3Link}Kool/Kool+_+The+Gang+-+Emergency.mp3`,
        },
        {
          name: "Fresh",
          source: `${s3Link}Kool/Kool+_+The+Gang+-+Fresh.mp3`,
        },
        {
          name: "Get Down on it",
          source: `${s3Link}Kool/Kool+_+The+Gang+-+Get+Down+on+it.mp3.mp3`,
        },{
          name: "Joanna",
          source: `${s3Link}Kool/Kool+_+The+Gang+-+Joanna.mp3`,
        }
        
      ],
    },
  ];

  const singleSongs = [
    {
      image:
        "https://ingilizcesarkilar.files.wordpress.com/2012/01/adele.jpg?w=584",
      name: "Adele",
      description: "",
      id: 11,
      type: 1,
      tracks: [
        {
          name: "Someone Like You",
          source: `${s3Link}Adele/Adele+-+Someone+Like+You.mp3`,
        },
      ],
    },
    {
      image:
        "https://i.ebayimg.com/images/g/2WwAAOSwSsxhDx0-/s-l300.jpg",
      name: "Ariana Grande",
      description: "",
      id: 12,
      type: 1,
      tracks: [
        {
          name: "Side To Side",
          source: `${s3Link}Ariana+Grande/Ariana+Grande+Side+To+Side+ft.+Nicki+Minaj.mp3`,
        },
      ],
    },
    {
      image:
        "https://img4.bdbphotos.com/images/230x300/p/9/p9ozsuaozo7e7zeo.jpg?skj2io4l",
      name: "P!nk",
      description: "",
      id: 13,
      type: 1,
      tracks: [
        {
          name: "A Million Dreams",
          source: `${s3Link}P_nk/P_nk+-+A+Million+Dreams.mp3`,
        },
      ],
    },
    {
      image:
        "https://img4.bdbphotos.com/images/230x300/b/1/b1ed6v1wd6xxvd16.jpg?skj2io4l",
      name: "Eric church",
      description: "",
      id: 14,
      type: 2,
      tracks: [
        {
          name: "Misunderstood",
          source: `${s3Link}Eric+Church/Eric+Church+-+Mr.+Misunderstood.mp3`,
        },
      ],
    },
    {
      image:
        "https://img4.bdbphotos.com/images/230x300/n/u/nuzfe12um4eq2eu1.jpg?skj2io4l",
      name: "Kool & The Gang",
      description: "",
      id: 15,
      type: 4,
      tracks: [
        {
          name: "Emergency",
          source: `${s3Link}Kool/Kool+_+The+Gang+-+Emergency.mp3`,
        },
      ],
    },
    {
      image:
        "https://img4.bdbphotos.com/images/230x300/t/d/tdxty1kjtx1atkxj.jpg?skj2io4l",
      name: "Chris Stapleton",
      description: "",
      id: 16,
      type: 2,
      tracks: [
        {
          name: "Broken Halos",
          source: `${s3Link}Chris+Stapleton/Chris+Stapleton+-+Broken+Halos.mp3`,
        },
      ],
    },
    {
      image:
        "https://media-cldnry.s-nbcnews.com/image/upload/t_fit-1500w,f_auto,q_auto:best/streams/2012/September/120905/521366-2012-07-12t221343z_2_cbre86b1pn200_rtroptp_3_music-hankwilliamsjr.jpg",
      name: "Hank Williams Jr.",
      description:
        "",
      id: 17,
      type: 2,
      tracks: [
        {
          name: "Family tradition",
          source: `${s3Link}Hank+Wiliams+Jr/Hank+Williams+jr+-+Family tradition.mp3`,
        },
      ],
    },
    {
      image:
        "https://img4.bdbphotos.com/images/230x300/4/0/40109yunuri0y0u9.jpg?skj2io4l",
      name: "Johnny Cash",
      description: "",
      id: 18,
      type: 2,
      tracks: [
        {
          name: "I Walk the Line",
          source: `${s3Link}Johnney+Cash/I+Walk+the+Line.mp3`,
        },
      ],
    }, 
  ];

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 992, itemsToShow: 4 },
    { width: 1200, itemsToShow: 5 },
  ];
  return (
    <InnerPagesLayout>
      <div className="wide_layout box-wide home-page">
        <div className="sections_wrapper">
          <section id="section_2" className="track_section">
            <div className="container ">
              <div className="row">
                <div className="col-xs-12">
                  <div className="section_head_widget">
                    <AnimatedOnScroll animationIn="fadeInDown" isVisible={true}>
                      <h4>latest In</h4>
                    </AnimatedOnScroll>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <div className="section_head_widget">
                    <AnimatedOnScroll animationIn="fadeInDown" isVisible={true}>
                      <h5>R&B/funk</h5>
                    </AnimatedOnScroll>
                  </div>
                </div>
              </div>
              <div className="row tracks_widget ">
                {latestAlbums &&
                  latestAlbums
                    .filter((nft) => nft.type === 4)
                    .map((nft) => (
                      <div className="col-lg-3 col-md-4 col-sm-12 text-center mb-4">
                        <HomeNFTCard nft={nft} key={nft.id} />
                      </div>
                    ))}
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <div className="section_head_widget">
                    <AnimatedOnScroll animationIn="fadeInDown" isVisible={true}>
                      <h5>Pop</h5>
                    </AnimatedOnScroll>
                  </div>
                </div>
              </div>
              <div className="row tracks_widget ">
                {latestAlbums &&
                  latestAlbums
                    .filter((nft) => nft.type === 1)
                    .map((nft) => (
                      <div className="col-lg-3 col-md-4 col-sm-12 text-center mb-4">
                        <HomeNFTCard nft={nft} key={nft.id} />
                      </div>
                    ))}
              </div>

              <div className="row mt-4 mb-0">
                <div className="col-xs-12">
                  <div className="section_head_widget">
                    <AnimatedOnScroll animationIn="fadeInDown" isVisible={true}>
                      <h5>Country</h5>
                    </AnimatedOnScroll>
                  </div>
                </div>
              </div>
              <div className="row tracks_widget ">
                {latestAlbums &&
                  latestAlbums
                    .filter((nft) => nft.type === 2)
                    .map((nft) => (
                      <div
                        className="col-lg-3 col-md-4 col-sm-12 text-center mb-4"
                        key={nft.id}
                      >
                        <HomeNFTCard nft={nft} />
                      </div>
                    ))}
              </div>
              <div className="row mt-4 mb-0">
                <div className="col-xs-12">
                  <div className="section_head_widget">
                    <AnimatedOnScroll animationIn="fadeInDown" isVisible={true}>
                      <h5>Hip hop</h5>
                    </AnimatedOnScroll>
                  </div>
                </div>
              </div>
              <div className="row tracks_widget ">
                {latestAlbums &&
                  latestAlbums
                    .filter((nft) => nft.type === 3)
                    .map((nft) => (
                      <div
                        className="col-lg-3 col-md-4 col-sm-12 text-center mb-4"
                        key={nft.id}
                      >
                        <HomeNFTCard nft={nft} />
                      </div>
                    ))}
              </div>
            </div>
          </section>

          <section id="newsAjaxWrapper" className="container">
            <a className="closeNewsAjax" href="section_2">
              <i className="fa fa-times"></i>
            </a>
            <div id="newsAjax" className="content_expander"></div>
          </section>

          <section id="section_5" className="track_section">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-8">
                  <div className="section_head_widget animatedParent ">
                    <AnimatedOnScroll animationIn="fadeInLeft" isVisible={true}>
                      <h4>Latest Music</h4>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll
                      animationIn="bounceInRight"
                      isVisible={true}
                    >
                      <h5>Recent songs</h5>
                    </AnimatedOnScroll>
                  </div>
                </div>
              </div>

              <div className="row tracks_widget songs-slider">
                <Carousel itemsToShow={4} breakPoints={breakPoints}>
                  {singleSongs &&
                    singleSongs.map((nft, index) => (
                      <HomeNFTCard nft={nft} key={nft.id} />
                    ))}
                </Carousel>
              </div>
            </div>
            <div className="clearfix"></div>

            <div className="trackLoading">
              <i className="fa fa-spin fa-refresh"></i>
            </div>
            <section id="#section_5" className="container">
              <a className="closeTrackAjax" href="/#">
                <i className="fa fa-times"></i>
              </a>
              <div
                id="tracksAjax"
                className="content_expander music_widget"
              ></div>
            </section>
          </section>
        </div>
      </div>
    </InnerPagesLayout>
  );
};
export default Home;
