import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { parseUnits } from "@ethersproject/units";
import CurrencyFormat from "react-currency-format";
import { Avatar } from "@mui/material";

const ShowPlaylist = ({ show, handleCloseParent, data, nftData }) => {
  const [showModal, setShowModal] = useState(show);

  const handleClose = () => {
    setShowModal(false);
    handleCloseParent();
  };


  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      scrollable={true}
      size="lg"
      backdrop="static"
      className="playListModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Avatar src={nftData.image} className="float-left mr-2" />
          songs of {nftData.name}{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-3">
        <table className="w-100">
          <tbody>
            {(data && data.length > 0) &&
              data.map((d, index) => (
                <tr key={index}>
                  <td>{index+1} . {d.name}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShowPlaylist;
