import React, { useState, useRef, useEffect, useCallback } from "react";
import { Web3Provider } from "@ethersproject/providers";
import  useGasPrice  from "../../connectors/hooks/GasPrice";
import useContractLoader from "../../connectors/hooks/ContractLoader";
import useUserProvider from "../../connectors/hooks/UserProvider";
import localProvider from "../../connectors/helpers/Provider";
import Transactor from "../../connectors/helpers/Transactor";
import { DEFAULT_CONTRACT_NAME, NETWORKS } from "../../connectors/constants";
import { ethers } from "ethers";
import { hexlify } from "@ethersproject/bytes";
import { parseUnits } from "@ethersproject/units";

import ActionButtons from "./next-back";
import { Spinner } from "react-bootstrap";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

const ImportNft = (props) => {
  const reactTags = useRef();

  const IntialInputs = () => ({
    inputs: {
      isAdvertise: false,
      existAddress: "",
    },
  });
  const [state, setState] = useState(IntialInputs());
  const [minting, setMinting] = useState(false);
  const [tags, setTags] = useState([]);
  const [injectedProvider, setInjectedProvider] = useState();
  const [RewardContract, setRewardContract] = useState(); //rewards contract state
  
  const userProvider = useUserProvider(injectedProvider, localProvider);
  const RewardContracts = useContractLoader(userProvider, "Rewards");
  //console.log("up RewardContracts", RewardContracts)
  //console.log("userProvider", userProvider)
  const signer = userProvider?.getSigner();

  const gasPrice = useGasPrice(NETWORKS.mumbai, "fast");
  const transactor = Transactor(injectedProvider, gasPrice);

  const loadWeb3Modal = useCallback(async () => {
    setInjectedProvider(new Web3Provider(window.ethereum));
  }, [setInjectedProvider]);

  useEffect(() => {
    loadWeb3Modal();
  }, [loadWeb3Modal]);

  const loadContract = useCallback(async () => {    
    const RewardContract = RewardContracts ? RewardContracts['RewardsContract'] : "";
    RewardContract && setRewardContract(RewardContract);
    // console.log("rewardContract,", RewardContract);
  }, [RewardContracts]);

  useEffect(() => {
      loadContract();
      //console.log("useEffect here")
  }, [loadContract]);


  const handleChange = (e) => {
    const { value, name, type, checked } = e.target;
    const { inputs } = state;

    inputs[name] = type === "checkbox" ? checked : value;
    setState({
      ...state,
      inputs,
    });
  };

  const handleSave = async() => {
    const trx = await RewardContract.mintNftContract(
      state.inputs.existAddress, tags[0]
    );
  };

  const onAddition = (tags) => {
    setTags(tags);
  };

  const sendData = () => {
    const nftData={name: state, rewardContract: RewardContract, signer: signer, TRANSACTOR: transactor, tokensList:tags}
      props.nextStep();
      props.ApplyNftData(nftData);
    
  };

  return (
    <div>
      <div className="row form-group">
        <div className="col-lg-7 text-center  form-group">
          <input
            type="text"
            name="existAddress"
            placeholder="Use existing NFT contract, enter the address"
            onChange={(e) => handleChange(e)}
            className="form-control"
            value={state.inputs.existAddress}
          />
        </div>
      </div>
      <div className="row  form-group">
        <div className="col-lg-12">
          <TagsInput
            value={tags}
            onChange={onAddition}
            className="form-control react-tag-control"
            inputProps={{placeholder: 'Add list of cards'}}
          />
        </div>
      </div>
      <div className="row  form-group">
        <div className="col-lg-12 ml-4">
          <input
            className="form-check-input"
            type="checkbox"
            checked={state.inputs.isAdvertise}
            id="isAdvertise"
            name="isAdvertise"
            onChange={(e) => handleChange(e)}
          />

          <label className="form-check-label" htmlFor="isAdvertise">
            is Advertisement
          </label>
        </div>
      </div>

      <div className="row  form-group">
        <div className="col-lg-12 text-center">
          <button
            onClick={handleSave}
            className="btn btn-danger btn-lg w-100"
            disabled={
              state.inputs.existAddress === ""
            }
          >
            {minting ? (
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                className="text-accent"
              />
            ) : (
              "Mint!"
            )}
          </button>
        </div>
      </div>

      <br />
      <ActionButtons {...props} nextStep={sendData} />

    </div>
  );
};

export default ImportNft;
