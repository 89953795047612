import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import validator from "validator";

const AddTrackModal = ({ show, handleCloseParent , handleAddParent}) => {
  const IntialInputs = () => ({
    inputs: {
      tokenId: "",
      image: "",
      name: "",
      description: "",
      source: "",
      trackDuration: "",
      rewardsRate: "", 
      isAd:false
    },
  });
  const [showModal, setShowModal] = useState(show);
  const [state, setState] = useState(IntialInputs());
  const [showErrors, setShowErrors] = useState();
  const [isAddToNft, setIsAddToNft] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    handleCloseParent();
  };

  const handleِAdd = async () => {
    setShowErrors(true);
    const { name, image, source } = state.inputs;
    if (name !== "" && image !== "" && source !== "") {
      setShowModal(false);
      handleAddParent(state.inputs, isAddToNft);
    }
  };

  const handleChange = (e) => {
    const { value, name, type, checked } = e.target;
    const { inputs } = state;

    inputs[name] = type === "checkbox" ? checked : value;

    setState({
      ...state,
      inputs,
    });
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      scrollable={true}
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add New Track</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row form-group">
          <div className="col-6 text-center">
            <input
              type="number"
              name="tokenId"
              value={state.inputs.tokenId}
              onChange={handleChange}
              placeholder="Token Id"
              className="form-control"
            />
            {showErrors === true && validator.isEmpty(state.inputs.tokenId.toString()) && (
              <div className="pay-beats-error-msg">Required</div>
            )}
          </div>
          <div className="col-6 text-center">
            <input
              type="text"
              name="name"
              value={state.inputs.name}
              onChange={handleChange}
              placeholder="Track Name"
              className="form-control"
            />
            {showErrors === true && validator.isEmpty(state.inputs.name) && (
              <div className="pay-beats-error-msg">Required</div>
            )}
          </div>
        </div>
        <div className="row form-group">
          <div className="col-6 text-center">
            <input
              type="text"
              name="source"
              value={state.inputs.source}
              onChange={handleChange}
              placeholder="Mp3 Source"
              className="form-control"
            />
            {showErrors === true && validator.isEmpty(state.inputs.source) && (
              <div className="pay-beats-error-msg">Required</div>
            )}
          </div>
          <div className="col-6 text-center">
            <input
              type="text"
              name="image"
              value={state.inputs.image}
              onChange={handleChange}
              placeholder="Track Image"
              className="form-control"
            />
            {showErrors === true && validator.isEmpty(state.inputs.image) && (
              <div className="pay-beats-error-msg">Required</div>
            )}
          </div>
        </div>
       
        <div className="row form-group">
          <div className="col-6 text-center">
            <input
              type="number"
              name="trackDuration"
              value={state.inputs.trackDuration}
              onChange={handleChange}
              placeholder="Track Duration In Seconds"
              className="form-control"
            />
            {showErrors === true && validator.isEmpty(state.inputs.trackDuration.toString()) && (
              <div className="pay-beats-error-msg">Required</div>
            )}
          </div>
          <div className="col-6 text-center">
            <input
              type="number"
              name="rewardsRate"
              value={state.inputs.rewardsRate}
              onChange={handleChange}
              placeholder="Rewards Rate in Beats"
              className="form-control"
            />
            {showErrors === true && validator.isEmpty(state.inputs.rewardsRate.toString()) && (
              <div className="pay-beats-error-msg">Required</div>
            )}
          </div>
        </div>

        <div className="row form-group">

          <div className="col-6 text-center">
            <input
              type="text"
              name="nftContract"
              value={state.inputs.nftContract}
              onChange={handleChange}
              placeholder="nft contract address"
              className="form-control"
            />
            {showErrors === true && validator.isEmpty(state.inputs.nftContract.toString()) && (
              <div className="pay-beats-error-msg">Required</div>
            )}
          </div>
          <div className="col-6">
              <input
                className="form-check-input"
                type="checkbox"
                checked={state.inputs.isAd}
                name="isAd"
                className="ml-0"
                onChange={(e) => handleChange(e)}
              />
              <label className="form-check-label" htmlFor="isAd" className="ml-2">
                is Advertisement
              </label>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-lg-12">
            <textarea
              value={state.inputs.description}
              className="form-control"
              name="description"
              placeholder="Track Description"
              onChange={handleChange}
            />
          </div>
        </div>
<div className="row  form-group">
        <div className=" col-lg-6 ">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="addtoNftType"
              id="addtocontractOption"
              onChange={() => setIsAddToNft(false)}
              value={!isAddToNft}
              checked={!isAddToNft}

            />
            <label className="form-check-label" htmlFor="addtocontractOption">
              Add to Contract
            </label>
          </div>
        </div>
        <div className="col-lg-6 ">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="addtoNftType"
              id="addtoNftOption"
              onChange={() => setIsAddToNft(true)}
              value={isAddToNft}
            />
            <label className="form-check-label" htmlFor="addtoNftOption">
              Add to the NFT
            </label>
          </div>
        </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn" onClick={handleClose}>
          Close
        </button>
        <button className="btn color-accent" onClick={handleِAdd}>
          Add
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTrackModal;
