import React from "react";
import { Row, Col, Button, FormGroup } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft,faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'

const ActionButtons = (props) => {
  const handleBack = () => {
    props.previousStep();
  };

  const handleNext = () => {
    props.nextStep();
  };


  return (
    <div>
      <Row className="mb-4">
        {props.currentStep > 1 && (
          <Col>
            <FontAwesomeIcon icon={faChevronCircleLeft} className="text-accent cursor-pointer"  onClick={handleBack} size="2x"/>

          </Col>
        )}
        <Col className="text-right">
          {props.currentStep < props.totalSteps && (
            <FontAwesomeIcon icon={faChevronCircleRight} className="text-accent cursor-pointer"  onClick={handleNext} size="2x"/>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ActionButtons;
