import React, { useEffect, useState, useCallback } from "react";
import { Web3Provider } from "@ethersproject/providers";
import { useUserAddress } from "eth-hooks";
import { Spinner } from "react-bootstrap";
import useGasPrice from "../../connectors/hooks/GasPrice";
import useContractLoader from "../../connectors/hooks/ContractLoader";
import useUserProvider from "../../connectors/hooks/UserProvider";
import localProvider from "../../connectors/helpers/Provider";
import Transactor from "../../connectors/helpers/Transactor";
import {
  DEFAULT_CONTRACT_NAME,
  NETWORKS,
  NFT_OLD_ADDRESS,
} from "../../connectors/constants";
import { useSelector } from "react-redux";
import { Contract } from "@ethersproject/contracts";
import NFTCard from "../nft-card/nftCard";
import networksMetaData from "../../connectors/helpers/loadUserNFTs/networksMetaData";
import loadUserNFTData from "../../connectors/helpers/loadUserNFTs/loadUserNFTData";
import { Link } from "react-router-dom";
//import { parseBytes32String } from 'eth-hooks/node_modules/ethers/lib/utils';
import { forEach } from "../../connectors/contracts/contracts";

function makeGatewayURL(ipfsURI) {
  return ipfsURI.replace(/^ipfs:\/\//, "https://dweb.link/ipfs/");
}

async function fetchIPFSJSON(ipfsURI) {
  const resp = await fetch("https://dweb.link/ipfs/" + ipfsURI);
  return resp.json();
}

async function getNFTImage(tokenMetaUri) {
  const metadata = await fetchIPFSJSON(tokenMetaUri);

  if (metadata.image) {
    metadata.image = makeGatewayURL(metadata.image);
  }

  return metadata.image;
}

const MyNFTs = ({ isCreation = false }) => {
  const authedUser = useSelector((state) => state.getData.authedUser);

  const [loading, setLoading] = useState(true);
  //const [userMetadata, setUserMetadata] = useState();
  const [userContractsNfts, setUserContractsNFts] = useState();

  const [injectedProvider, setInjectedProvider] = useState();
  const [OldContract, setOldContract] = useState();
  const [RewardContract, setRewardContract] = useState(); // rewards contract state
  const [tracksContract, setTracksContract] = useState(); // tracks contract state

  const userProvider = useUserProvider(injectedProvider, localProvider);
  const NftContracts = useContractLoader(userProvider, "Old");
  const RewardContracts = useContractLoader(userProvider, "Rewards");
  const tracksContracts = useContractLoader(userProvider, "tracks");
  //console.log("tracksContracts", tracksContracts);

  //console.log("userProvider", userProvider)
  const userAddress = useUserAddress(userProvider);

  const gasPrice = useGasPrice(NETWORKS.mumbai, "fast");
  const transactor = Transactor(injectedProvider, gasPrice);
  //const [userTracks, setUserTracks] = useState();
  const currentAddress = localStorage.getItem("address");

  const loadWeb3Modal = useCallback(async () => {
    setInjectedProvider(new Web3Provider(window.ethereum));
  }, [setInjectedProvider]);

  useEffect(() => {
    loadWeb3Modal();
  }, [loadWeb3Modal]);

  const getCurrentUserTokens = useCallback(
    async (requiredContracts) => {
      if (userAddress) {
        try {
          console.log(window.ethereum);
          let network = await userProvider.getNetwork();
          let chainId = network.chainId;
          // console.log('chainId', chainId);
          // let userAddress = await signer.getAddress();
          // console.log("userAddress", userAddress);
          let chainMetaData = networksMetaData[chainId];
          // console.log('network metadata', chainMetaData);
          chainMetaData.provider = userProvider;
          //const addedNftContract = await RewardContract.getContractTokensList(contract.address);
          //console.log('addedNFt', addedNftContract);
          return await loadUserNFTData(
            chainMetaData,
            userAddress,
            requiredContracts
          );
        } catch (error) {
          // console.log('error at signer');
          return undefined;
        }
      }
      return undefined;
    },
    [userProvider, userAddress]
  );

  const getCreationTokens = useCallback(
    async (OldContract) => {
      if (currentAddress) {
        try {
          let returnedCards = [];
          const cards = await OldContract.getCreatorCards(currentAddress);
          for (let i = 0; i < cards.length; i++) {
            let card = cards[i];
            const metadata = await getNFTImage(card.uri);

            returnedCards.push({
              NFTCreator: card.creator,
              contractAddress: OldContract.address.toLowerCase(),
              description: " ",
              image: metadata,
              name: card.name,
              networkChainId: 80001,
              tokenID: parseInt(card.id._hex, 16),
              album: card.album,
            });
          }

          return returnedCards;
        } catch (error) {
          console.log("error at signer", error);
          return undefined;
        }
      }

      return undefined;
    },
    [userProvider, userAddress]
  );

  const getNftIdTracks = async (contract, contractAddress, ownerTokenIds) => {
    let tracks_map = new Map();
    for (let i = 0; i < ownerTokenIds.length; i++) {
      const token_id = ownerTokenIds[i];
      // console.log("tokenId", token_id);
      // console.log("contractAddress", contractAddress);
      const temp = await contract.getGenericAndSpecificNftTracks(
        contractAddress,
        token_id
      );
      // console.log('temp', temp);

      let fe = temp.filter(
        (k) =>
          k.contractAddress !== "0x0000000000000000000000000000000000000000"
      );
      tracks_map.set(token_id, fe);
    }
    return tracks_map;
  };

  const loadNftData = useCallback(async () => {
    try {
      let contractsInfo = []; // to be initialized before loop through the contract address
      let filteredTokens = [];

      // const userAddress = await signer.getAddress();
      // debugger;
      const OContract = NftContracts ? NftContracts["PaybeatsNFT"] : "";
      OContract && setOldContract(OldContract);

      const RContract = RewardContracts
        ? RewardContracts["RewardsContract"]
        : "";
      RContract && setRewardContract(RContract);

      const tracksContract = tracksContracts
        ? tracksContracts["PaybeatsTracks"]
        : "";
      tracksContract && setTracksContract(tracksContract);
      // debugger;
      const masterList = await RContract.getContractsList();
      const uniqueMasterList = [...new Set(masterList)].map((contractAddress) =>
        contractAddress.toLowerCase()
      ); // list of all contracts
      let userTokens;

      if (!isCreation) {
        userTokens = await getCurrentUserTokens(new Set(uniqueMasterList));
        // console.log('user tokens library', userTokens);
        //console.log("userTokens", userTokens);
        try {
          userTokens = userTokens.filter(
            (token) => token.isAdvertisement == false
          );
        } catch (e) {
          console.log("errfo filtereing nft card");
        }
      } else {
        userTokens = OContract && (await getCreationTokens(OContract));
        // console.log("userTokens inside creations", userTokens);
      }
      //console.log("usertokens creation", userTokens);

      filteredTokens = userTokens;
      /*try {
          filteredTokens = userTokens.filter(
            (token) => token.NFTCreator == userAddress
          );
          //console.log("filteredTokens", filteredTokens)
        } catch (e) {
          console.log('errfo filtereing nft card');
        }*/

      let contractValidTokenIds = {};
      for (let contractAddress of uniqueMasterList) {
        contractValidTokenIds[contractAddress] = new Set(
          (await RContract.getContractTokensList(contractAddress)).map((obj) =>
            parseInt(obj._hex, 16)
          )
        );
      }
      // console.log('contract valid tokenids', contractValidTokenIds);
      //console.log("contractValidTokenIds", contractValidTokenIds)

      let userValidTokenIds = {};
      // console.log("filteredTokens", filteredTokens);

      filteredTokens.forEach((token) => {
        // debugger;
        let contractAddress = token.contractAddress;
        //console.log("token.tokenID", token.tokenID)
        // console.log("token.contractAddress", token.contractAddress)
        // console.log("contractValidTokenIds[contractAddress] out", contractValidTokenIds[contractAddress])
        // console.log("token.name", token.name);
        // console.log("toknn", token);
        // console.log("toknnID", token.tokenID);
        // console.log("first if", contractValidTokenIds[contractAddress].has(
        //   parseInt(token.tokenID, 16) ));
        // console.log("secnd if condition", contractValidTokenIds[contractAddress].has(
        //   token.tokenID));
        // console.log("type", token.tokenID)

        if (
          contractValidTokenIds[contractAddress].has(parseInt(token.tokenID)) ||
          contractValidTokenIds[contractAddress].has(token.tokenID)
        ) {
          // console.log("inside");
          //console.log("userValidTokenIds[contractAddress]", userValidTokenIds[contractAddress]);
          if (userValidTokenIds[contractAddress] === undefined)
            userValidTokenIds[contractAddress] = [];
          userValidTokenIds[contractAddress].push(token);
        }
      });
      // console.log("userValidTokenIds", userValidTokenIds)

      if (isCreation) {
        var resArr = [];
        var resMap = {};
        const albums = new Set();
        for (var key in userValidTokenIds) {
          resMap[key] = [];
          userValidTokenIds[key].filter((token) => {
            if (!albums.has(token.album)) {
              resMap[key].push(token);
              // console.log('resMap inside', resMap);
              resArr.push(token);
              albums.add(token.album);
            }
          });
        }
        // console.log("resArr", resArr)
        // console.log("resMap", resMap)
        userValidTokenIds = resMap;
      }

      for (let contractAddress in userValidTokenIds) {
        let ownerTokenIds = userValidTokenIds[contractAddress].map(
          (token) => token.tokenID
        );

        let NftTracksMeta = await getNftIdTracks(
          tracksContract,
          contractAddress,
          ownerTokenIds
        );

        let contractInfo = {
          contract_address: contractAddress, // to be change with the contract address from the loop.
          tokens: userValidTokenIds[contractAddress],
          token_tracks: NftTracksMeta,
        };
        contractsInfo.push(contractInfo);
        //console.log('Info', contractInfo);
      }

      contractsInfo && setUserContractsNFts(contractsInfo); // added outside the loop
      contractsInfo && setLoading(false);
      //setLoading(false);
    } catch (error) {
      console.log("check contract loading");
    }
  }, [
    NftContracts,
    RewardContracts,
    getCurrentUserTokens,
    isCreation,
    tracksContracts,
    userAddress,
  ]);

  // useEffect(() => {
  //   if (userContractsNfts) {
  //     console.log('len', userContractsNfts.length);
  //     setLoading(false);
  //   }
  // }, [userContractsNfts]);

  useEffect(() => {
    if (!userContractsNfts) {
      loadNftData();
      //console.log("useEffect here")
    }
  }, [loadNftData, userContractsNfts]);

  return (
    <>
      {!!loading ? (
        <div className="row justify-content-center">
          <div className="col-lg-12 col-md-12 col-sm-12 text-center mb-4">
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
              className="text-accent"
            />
          </div>
        </div>
      ) : (
        <>
          {/*
                {userMetadata && userMetadata.length > 0 && userTracks && userTracks.size > 0? (
                    userMetadata &&
                      userMetadata.map((nft, index) => (
                      <NFTCard
                        key={index}
                        nft={nft}
                        transactor={transactor}
                        userProvider={userProvider}
                        userTracks={userTracks}
                        nftIndex={parseInt(nft.id._hex.toString(), 16)}
                        vestingContract={RewardContract}
                      />
                      ))
                ) : (
                  <span>No Data To Show</span>
                )}
                */}

          {userContractsNfts && userContractsNfts.length > 0 ? (
            <div className="row">
              {userContractsNfts.map((contractInfo, index) =>
                Object.values(contractInfo.tokens).map((nft, index) => (
                  <NFTCard
                    key={index}
                    nft={nft}
                    transactor={transactor}
                    userProvider={userProvider}
                    userTracks={contractInfo.token_tracks}
                    nftIndex={nft.tokenID}
                    vestingContract={RewardContract}
                    contractAddress={contractInfo.contract_address}
                    isLibrary={!isCreation}
                  />
                ))
              )}
            </div>
          ) : (
            <div className="row">
              {" "}
              <div className="col text-center">
                {isCreation ? (
                  <div>What? No Music NFTs yet? Create some Now!</div>
                ) : (
                  <div>
                    What? No Music NFTs yet? Get you some from{" "}
                    <Link to={"/music-bin"} className="text-accent">
                      Music Bin!{" "}
                    </Link>
                    Now!
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default MyNFTs;
