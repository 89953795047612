import React, { useEffect, useState, useCallback } from "react";
import InnerPagesLayout from "../component/InnerPagesLayout";
import logo from "../assets/img/basic/mainLogo.png";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStaylinked } from "@fortawesome/free-brands-svg-icons";
import { faCompactDisc } from "@fortawesome/free-solid-svg-icons";
import { faMoneyBillAlt } from "@fortawesome/free-regular-svg-icons";

import { Web3Provider } from "@ethersproject/providers";
import { BigNumber } from "@ethersproject/bignumber";
import { Spinner } from "react-bootstrap";
import { useGasPrice } from "eth-hooks";
import useContractLoader from "../connectors/hooks/ContractLoader";
import useUserProvider from "../connectors/hooks/UserProvider";
import localProvider from "../connectors/helpers/Provider";
import Transactor from "../connectors/helpers/Transactor";
import { DEFAULT_CONTRACT_NAME, NETWORKS } from "../connectors/constants";
import { useSelector } from "react-redux";

function makeGatewayURL(ipfsURI) {
  return ipfsURI.replace(/^ipfs:\/\//, "https://dweb.link/ipfs/");
}

async function fetchIPFSJSON(ipfsURI) {
  const resp = await fetch("https://dweb.link/ipfs/" + ipfsURI);
  return resp.json();
}

async function getNFTImage(tokenMetaUri) {
  const metadata = await fetchIPFSJSON(tokenMetaUri);

  if (metadata.image) {
    metadata.image = makeGatewayURL(metadata.image);
  }

  return metadata.image;
}



const PaybeatsTokens = () =>  {

  const [loading, setLoading] = useState(true);
  const [injectedProvider, setInjectedProvider] = useState();
  const [OldContract, setOldContract] = useState();

  const userProvider = useUserProvider(injectedProvider, localProvider);
  const NftContracts = useContractLoader(userProvider, "Old");

  const signer = userProvider?.getSigner();
  const gasPrice = useGasPrice(NETWORKS.mumbai, "fast");
  const transactor = Transactor(injectedProvider, gasPrice);

  const [allTokens, setUserTracks] = useState();

  const loadWeb3Modal = useCallback(async () => {
    setInjectedProvider(new Web3Provider(window.ethereum));
  }, [setInjectedProvider]);

  useEffect(() => {
    loadWeb3Modal();
  }, [loadWeb3Modal]);

  const getCurrentTokens = useCallback(
    async (contract) => {
      // console.log("here")
      const NftsMetaData = [];
      const currentTokens = await contract.getAllTokens(); /** call contract to get all nfts */

      for (var token of currentTokens) {
        const emptyAddress = /^0x0+$/.test(token.owner);
        if (!emptyAddress) {    /** Check if the nft is already burnt */
          const image = await getNFTImage(token.uri);
          NftsMetaData.push({ ...token, image });
        }
      }
      return NftsMetaData;
    },
    []
  );

  const loadNftData = useCallback(async () => {
    
    /** Load NFTS' contract */
    const OldContract = NftContracts? NftContracts['PaybeatsNFT'] : "" ; 
    OldContract && setOldContract(OldContract);
    // console.log("OldContract: ", OldContract);

    /** Get All IDs' Of The Current NFTs Tokens */
    const getNFTIds = OldContract && (await getCurrentTokens(OldContract));
    // console.log("getNFTIds: ", getNFTIds);
    const NFTIds = getNFTIds && [...getNFTIds];

    /** Set State To Stop Spinner And Display In UI */
    !!NFTIds && setUserTracks(NFTIds);
    !!NFTIds && setLoading(false);
    
    // console.log("NFTS", NFTIds);
    
    return allTokens;

  }, [NftContracts, getCurrentTokens, allTokens]);  /** These props to watch incase changes will fire the callback */

  useEffect(() => {
    if (!allTokens) {
      loadNftData();
    }
  }, [loadNftData, allTokens]);

  return (
    <InnerPagesLayout>
      <div className="container tokens_page">
        <div className="row justify-content-center">
          <div className="col-xs-12">
            <div className="section_head_widget animatedParent ">
              <AnimatedOnScroll animationIn="fadeInDown" isVisible={true}>
                <img src={logo} alt="logo" className="img img-fluid logo" />
              </AnimatedOnScroll>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-sm-4 col-xs-12 animatedParent">
            <AnimatedOnScroll animationIn="fadeInUp" isVisible={true}>
              <div className="row">
                <div className="col-lg-2">
                  <FontAwesomeIcon size="2x"  icon={faStaylinked} className="icon fa-w-14"/>
                </div>
                <div className="col-lg-10">
                  <h6>Connect your Wallet</h6>
                  <p>Connect your wallet so we can search for your NFT</p>
                </div>
              </div>
            </AnimatedOnScroll>
          </div>

          <div className="col-sm-4 col-xs-12 animatedParent">
            
            <AnimatedOnScroll animationIn="fadeInUp" isVisible={true}>
            <div className="row">
                <div className="col-lg-2">
                  <FontAwesomeIcon size="2x" icon={faCompactDisc} className="icon fa-w-14"/>
                </div>
                <div className="col-lg-10">
                <h6>NFTs containing playlist</h6>
              <p>Choose your favorite playlist to play</p>
                </div>
              </div>
            </AnimatedOnScroll>
          </div>

          <div className="col-sm-4 col-xs-12 animatedParent">
            <AnimatedOnScroll animationIn="fadeInUp" isVisible={true}>
            <div className="row">
                <div className="col-lg-2">
                  <FontAwesomeIcon size="2x" icon={faMoneyBillAlt} className="icon fa-w-14"/>
                </div>
                <div className="col-lg-10">
                <h6>Earn Paybeats coins</h6>
              <p>Earn PayBeats coins for playing your song lists.</p>
                </div>
              </div>
              
            </AnimatedOnScroll>
          </div>
        </div>

        {!!loading ? (
            <div className="row justify-content-center mt-5">
              <div className="col-lg-12 col-md-12 col-sm-12 text-center mb-4">
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                  className="text-accent"
                />
              </div>
            </div>
          ) : (
            <>
               <div className="row justify-content-center mt-5">
                  {allTokens && allTokens.length > 0 ? (
                    allTokens &&
                      allTokens.map((nft, index) => (
                        <div
                          key={index}
                          className="col-lg-4 col-md-6 col-sm-12 text-center mb-4"
                        >
                          <div className="card text-center h-100">
                            <div className="card-header">
                              {" "}
                              <img
                                src={nft.image}
                                alt="nft"
                                className="img-fluid"
                              />
                            </div>
                            <div className="card-body">
                              <h5 className="card-title  text-accent">
                                {nft.name}
                              </h5>
                            </div>
                            <div className="card-footer text-muted">
                              Category: {nft.category}
                            </div>
                          </div>
                        </div>
                      ))
                  ) : (
                    <div className="row justify-content-center mt-5">
                      <h2>No Data To Show</h2>
                    </div>
                  )}
               </div>
            </>
          )
        }
      </div>
    </InnerPagesLayout>
  );
}
export default PaybeatsTokens;
