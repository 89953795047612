import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const TracksTable = (props) => {
  const data = props.tracks;
  const tempImg =
    "https://i.guim.co.uk/img/media/fc234e96d3baa28db89e86b8dad0ec57ea61fc45/0_0_3900_4447/master/3900.jpg?width=300&quality=45&auto=format&fit=max&dpr=2&s=a64a93a8e6f27897e9b417feb98e3872";

  const [tracks, setTracks] = useState();

  return (
    <section id="section_9" className="tours_section">
      <table className="container">
        <tbody>
          {" "}
          <tr className="">
            <th width="70">&nbsp;</th>
            <th> Name </th>
            <th> MP3 Source </th>
            <th> nft contract address</th>
            <th> Token Id </th>
            <th> Duration </th>
            <th> Rewards Rate </th>
            <th> </th>
          </tr>
          {data.map((track) => (
            <tr key={parseInt(track.id._hex, "16")} className=" ">
              <td>
                <img src={tempImg} alt="track" />
              </td>
              <td>
                <span>{track.name}</span>
              </td>
              <td> {track.source} </td>
              <td> {track.contractAddress}</td>
              <td> {parseInt(track.tokenId._hex, "16")}</td>
              <td> {parseInt(track.trackDuration._hex, "16")} Sec.</td>
              <td>
                {" "}
                {parseInt(track.rewardsRate._hex, "16") / 10 ** 18} BEATS
              </td>
              <td>
                {" "}
                <FontAwesomeIcon icon={faTrash} className="cursor-pointer" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};
export default TracksTable;
