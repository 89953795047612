import React, { useEffect, useState, useCallback } from "react";
import InnerPagesLayout from "../component/InnerPagesLayout";
import validator from "validator";
import useUserProvider from "../connectors/hooks/UserProvider";
import localProvider from "../connectors/helpers/Provider";



const Profile = () => {

  const [injectedProvider, setInjectedProvider] = useState();

  const userProvider = useUserProvider(injectedProvider, localProvider);
  const signer = userProvider?.getSigner();
  console.log("signer", signer);

  const IntialInputs = () => ({
    inputs: {
      name: "",
      email: "",
      wallet: "",
      phoneNumber: "",
      bio: "",
    },
  });
  
  const currentAddress = localStorage.getItem('address');
  const [state, setState] = useState(IntialInputs());
  const [showErrors, setShowErrors] = useState();


  const handleِSave = async () => {
    setShowErrors(true);
    const { name, email, wallet, phoneNumber, bio } = state.inputs;
    if (
      name !== "" &&
      email !== "" &&
      wallet !== "" &&
      phoneNumber !== "" &&
      bio !== ""
    ) {
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    const { inputs } = state;

    inputs[name] = value;
    setState({
      ...state,
      inputs,
    });
  };

  return (
    <InnerPagesLayout>
      <div className="dg__service__area service-space bg--white">
        <div className="container">
          {/* video popup */}
          {/* {userMetadata === "" ? ( */}
           {currentAddress&& <div className="row justify-content-center align-items-center  mt-5">
            <div className="col-lg-7">
              Your Wallet Address: {currentAddress}
              </div>
              </div>}
            <div className="row justify-content-center align-items-center mt-2">
            <div className="col-lg-7">
           
              <div className="row form-group">
                <div className="col-6 text-center">
                  <input
                    type="text"
                    name="name"
                    value={state.inputs.name}
                    onChange={handleChange}
                    placeholder="Full Name*"
                    className="form-control"
                  />
                  {showErrors === true &&
                    validator.isEmpty(state.inputs.name) && (
                      <div className="pay-beats-error-msg">Required</div>
                    )}
                </div>
                <div className="col-6 text-center">
                  <input
                    type="email"
                    name="email"
                    value={state.inputs.email}
                    onChange={handleChange}
                    placeholder="Email*"
                    className="form-control"
                  />
                  {showErrors === true &&
                    validator.isEmpty(state.inputs.email) && (
                      <div className="pay-beats-error-msg">Required</div>
                    )}
                </div>
              </div>
              <div className="row form-group">
                <div className="col-6 text-center">
                  <input
                    type="text"
                    name="wallet"
                    value={state.inputs.wallet}
                    onChange={handleChange}
                    placeholder="Wallet Public Key"
                    className="form-control"
                  />
                  {showErrors === true &&
                    validator.isEmpty(state.inputs.wallet) && (
                      <div className="pay-beats-error-msg">Required</div>
                    )}
                </div>
                <div className="col-6 text-center">
                  <input
                    type="text"
                    name="phoneNumber"
                    value={state.inputs.phoneNumber}
                    onChange={handleChange}
                    placeholder="Phone Number"
                    className="form-control"
                  />
                  {showErrors === true &&
                    validator.isEmpty(state.inputs.phoneNumber) && (
                      <div className="pay-beats-error-msg">Required</div>
                    )}
                </div>
              </div>
              <div className="row form-group">
                <div className="col-lg-12">
                  <textarea
                    value={state.inputs.bio}
                    className="form-control"
                    name="bio"
                    placeholder="Bio"
                    onChange={handleChange}
                  />
                  {showErrors === true &&
                    validator.isEmpty(state.inputs.bio) && (
                      <div className="pay-beats-error-msg">Required</div>
                    )}
                </div>
              </div>
              <div className="row form-group">
                <div className="col-lg-12">
                  <button
                    className="btn btn-warning btn-lg"
                    onClick={() => {
                      handleِSave();
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </InnerPagesLayout>
  );
};
export default Profile;
