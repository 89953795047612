import React from "react";
import { StaticJsonRpcProvider } from "@ethersproject/providers";
import { useUserAddress } from "eth-hooks";
import { useDispatch } from "react-redux";
import { Account } from "./components";
import { NETWORK, NETWORKS } from "./constants";
import { useAddress } from "./hooks";
import { setauthedUser,setIsPaused,setIsPlayedNFT,setPlayedNFT,setplayList } from "../store/actions/action";
import { useHistory, useLocation } from "react-router-dom";

const targetNetwork = NETWORKS.mumbai;

const DEBUG = false;

// 🛰 providers
if (DEBUG) console.log("📡 Connecting to Smart Chain - Testnet");

const localProviderUrl = targetNetwork.rpcUrl;
const localProviderUrlFromEnv = process.env.REACT_APP_PROVIDER
  ? process.env.REACT_APP_PROVIDER
  : localProviderUrl;

if (DEBUG) console.log("🏠 Connecting to provider:", localProviderUrlFromEnv);
const localProvider = new StaticJsonRpcProvider(localProviderUrlFromEnv);

const blockExplorer = targetNetwork.blockExplorer;

function Web3Auth({
  onConnectAccount = () => {},
  onDisconnectAccount = () => {},
}) {
  const currentProvider = useAddress();
  let history = useHistory();
  let location = useLocation();
  const address = useUserAddress(currentProvider.injectedProvider);
  const userProvider = currentProvider.userProvider;
  const loadWeb3Modal = currentProvider.loadWeb3Modal;
  const web3Modal = currentProvider.web3Modal;
  const logoutOfWeb3Modal = currentProvider.logoutOfWeb3Modal;
  const localChainId =
    localProvider && localProvider._network && localProvider._network.chainId;
  const selectedChainId =
    userProvider && userProvider._network && userProvider._network.chainId;
  const dispatch = useDispatch();
  const changeAccountState = () => {
    onConnectAccount();
    dispatch(setauthedUser("true"));
  };

  const DisconnectAccountState = () => {
    onDisconnectAccount();
    dispatch(setauthedUser("false"));
    dispatch(setplayList([]));
    dispatch(setIsPlayedNFT("false"));
    dispatch(setPlayedNFT({}));
    dispatch(setIsPaused("false"));
  };

  let networkDisplay: any = "";
  const pathname = location.pathname;
  if (web3Modal.cachedProvider === "" && address === "") {
    if (pathname.toLowerCase() === "/") {
      history.push("/home");
    }
  } else {
    if (pathname.toLowerCase() === "/") {
      history.push("/my-library");
    }
  }
  if (localChainId && selectedChainId && localChainId !== selectedChainId) {
    const networkSelected = NETWORK(selectedChainId);
    const networkLocal = NETWORK(localChainId);
    if (selectedChainId !== 80001) {
      networkDisplay = (
        <div>
          {/* <Alert severity='error'>
            <p>⚠️ Wrong Network</p>
            <div>
              You have <b>{networkSelected && networkSelected.name}</b> selected
              and you need to be on <b>{networkLocal && networkLocal.name}</b>.
            </div>
          </Alert> */}
        </div>
      );
    }
  } else {
    //networkDisplay = (
    // <div
    //   style={{
    //     color: targetNetwork.color,
    //   }}
    // >
    //   {targetNetwork.name}
    // </div>
    //  );
  }

  return (
    <div>
      {address ? networkDisplay : ""}
      {address ? localStorage.setItem('address', address):""}
      <Account
        address={address}
        userProvider={userProvider}
        price={0}
        web3Modal={web3Modal}
        loadWeb3Modal={loadWeb3Modal}
        logoutOfWeb3Modal={logoutOfWeb3Modal}
        blockExplorer={blockExplorer}
        changeAccountState={changeAccountState}
        DisconnectAccountState={DisconnectAccountState}
      />
    </div>
  );
}

export { Web3Auth };
