//import liraries
import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";
import { lockScreen } from "../assets/img/index";
// create a component
const LockScreen = () => {
  let history = useHistory();
  const [password, setPassword] = useState("");
  // const [validPass, setValidPass] = useState("");

  const navigate = () => {
    ReactGA.event({
      category: "Start Home Button",
      action: `start page login with password: ${password}`,
    });
    if (password.length > 0) {
      if (password === process.env.REACT_APP_WEBSITE_PASSWORD) {
        window.$isValidUser = true;
        history.push("/home");
      } else {
        window.alert("Wrong password");
        window.$isValidUser = false;
      }
    }
  };
  function handleSubmit(event) {
    event.preventDefault();
  }
  useEffect(() => {
    window.$isValidUser = false;
    ReactGA.pageview("/Start-Page");
  }, []);
  return (
    <div
      className=" section-padding--xlg start-lock-page fullscreen min-h-100vh d-flex align-items-center"
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(25, 25, 25, 0.3), rgba(8, 8, 8, 0.5)),url(${lockScreen})`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `cover`,
      }}
    >
      <div className="container text-center">
        <div className="text-center">
          <h5 className="text-white">Access Restricted </h5>
          <h5 className="text-white">Please Enter The Password</h5>
        </div>
        <Form onSubmit={handleSubmit}>
          <Form.Group size="lg" controlId="password">
            <Form.Label> </Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <button
            className="btn btn-warning btn-lg"
            onClick={() => navigate()}
          >
            Start
          </button>
        </Form>
      </div>
    </div>
  );
};

//make this component available to the app
export default LockScreen;
