import React, { useEffect, useState, useCallback } from "react";

import {
  demoIcon,
  playIcon,
  pauseIcon,
  musicIcon
} from "../../assets/img/index";
import { setIsPaused, setplayList } from "../../store/actions/action";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import { Card, Row, Col, Spinner } from "react-bootstrap";
import ShowPlaylist from "../modals/showPlayList";
import NFTTwitterShare from "../NFTTwitterShare";
/** For import wallet using its private key */
const Web3 = require("web3");
const HDWalletProvider = require("truffle-hdwallet-provider-privkey");
const RewardABI =
  require("../../connectors/contracts/RewardsContract.json").abi;

const HomeNFTCard = ({ nft }) => {
  const isNftPlayed = useSelector((state) => state.getData.isNftPlayed);
  const playedNft = useSelector((state) => state.getData.playedNft);
  const isPaused = useSelector((state) => state.getData.isPaused);

  const [loaded, setLoaded] = useState(false);
  const [showIcons, setShowIcons] = useState(false);
  const [selectedPlayList, setSelectedPlayList] = useState();
  const [showPlayListModal, setShowPlayListModal] = useState();

  const dispatch = useDispatch();

  const pausePlayer = () => {
    dispatch(setIsPaused(isPaused === "true" ? "false" : "true"));
  };

  const getPlayList = () => {
    const nftTracks = nft.tracks;
    let tracksArr = [];
    nftTracks.forEach((track, index) => {
      let singleTrack = {
        name: track.name,
        singer: nft.name,
        cover: nft.image,
        musicSrc: track.source,
        isDemo: true,
        id: nft.id,
      };
      tracksArr.push(singleTrack);
    });
    // console.log(tracksArr);
    dispatch(setplayList(tracksArr));
  };

  const onImageLoaded = () => {
    setLoaded(true);
  };

  const handleShowPlaylist = () => {
    const nftTracks = nft.tracks;
    let tracksArr = [];
    nftTracks.forEach((track, index) => {
      let singleTrack = {
        name: track.name,
        singer: nft.name,
        cover: nft.image,
        musicSrc: track.source,
        isDemo: true,
        id: nft.id,
      };
      tracksArr.push(singleTrack);
    });
      setSelectedPlayList(tracksArr);
      setShowPlayListModal(true);
   
  };

  return (
      <Card
        className="nft-card h-100"
        onMouseEnter={(e) => {
          setShowIcons(true);
        }}
        onMouseLeave={(e) => {
          setShowIcons(false);
        }}
      >
        <div className="position-relative">
          <Avatar
            src={nft.image}
            onLoad={onImageLoaded}
            className="card-img img img-fluid border-radius-0"
          />
          {!loaded && (
            <div className="image-container-overlay">
              <Spinner animation="border" variant="danger" />
            </div>
          )}
            <img src={demoIcon} className="position-absolute demo-icon"/>
        </div>
        <Card.Body>
          <Card.Title>{nft.name}<br/><p className="fz-14 text-muted mt-1">{nft.tracks.length} Songs</p></Card.Title>
          <Card.Text className="mb-4 fz-14 text-muted">
            {nft.description}
          </Card.Text>
          <Row className="nft-card-icons">
          <Col className={`text-end ${showIcons ? "showIcons" : "hideIcons"}`}>
                <NFTTwitterShare item={nft} isWithoutText={true} className="pull-left"/>
            </Col>
            <Col
              className={`text-end ${showIcons ? "showIcons" : "hideIcons"}`}
            >
                  
             <img
                  src={musicIcon}
                  alt="play"
                  className="img img-fluid"
                  onClick={handleShowPlaylist}
                 
                />
                {showPlayListModal &&selectedPlayList.length>0 && (
                  <ShowPlaylist
                    show={showPlayListModal}
                    handleCloseParent={() => {
                      setShowPlayListModal(false);
                    }}
                    data={selectedPlayList}
                    nftData={nft}
                  />
                )}
            
              {isNftPlayed === "true" && playedNft?.id === nft.id ? (
                <img
                  src={pauseIcon}
                  alt="play"
                  className="img img-fluid"
                  onClick={() => {
                    pausePlayer();
                  }}
                />
              ) : (
                <img
                  src={playIcon}
                  alt="play"
                  className="img img-fluid"
                  onClick={() => {
                    getPlayList(nft.id);
                  }}
                />
              )}
                
            </Col>
            
          </Row>
        </Card.Body>
      </Card>
  );
};
export default HomeNFTCard;
