import React, { useState } from "react";
import { Web3Auth } from "../connectors/Auth.tsx";
import { Navbar, Nav } from "react-bootstrap";
import logo from "../assets/img/basic/Logo.png";
import logoText from "../assets/img/basic/logotext.png";
import menu from "../assets/img/basic/menu-icon.png";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid
} from "@fortawesome/fontawesome-svg-core/import.macro";

function Menu() {
  const authedUser = useSelector((state) => state.getData.authedUser);
  const isNftPlayed = useSelector((state) => state.getData.isNftPlayed);

  let location = useLocation();
  const path = location.pathname.toLowerCase();
  const [connected, setConnected] = useState(false);
  return (
    <Navbar collapseOnSelect expand="lg" className="px-3 navbar">
      <Link to="/home" className="navbar-brand">
        <img src={logo} alt="logo" className={`${isNftPlayed==="true"?"rotate":""}`}/>
        <img src={logoText} alt="logo" />
      </Link>
      <Navbar.Toggle>
        <img src={menu} alt="menu" />
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mx-auto">
          <Link
            className={`nav-link text-center ${
              path === "/home" ? "active" : ""
            }`}
            to="/home"
          >
            <FontAwesomeIcon icon={solid("home")} />
            <span className="d-block">Home</span>
          </Link>
          {authedUser === "true" ? (
            <>
              <Link
                to="/my-library"
                className={`nav-link text-center ${
                  path === "/my-library" ? "active" : ""
                }`}
              >
                <FontAwesomeIcon icon={solid("headphones-simple")} />
                <span className="d-block">My Library</span>
              </Link>
              <Link
                to="/my-rewards"
                className={`nav-link text-center ${
                  path === "/my-rewards" ? "active" : ""
                }`}
              >
                <FontAwesomeIcon icon={solid("gift")} />
                <span className="d-block">My Rewards</span>
              </Link>
              <Link
                to="/my-creations"
                className={`nav-link text-center ${
                  path === "/my-creations" ? "active" : ""
                }`}
              >
                <FontAwesomeIcon icon={solid("microphone-lines")} />
                <span className="d-block">My Creations</span>
              </Link>
            </>
          ) : (
            ""
          )}
          <Link
            className={`nav-link text-center ${
              path === "/about" ? "active" : ""
            }`}
            to="/about"
          >
            <FontAwesomeIcon icon={solid("info")} />
            <span className="d-block"> About Us</span>
          </Link>
          <Link
            to="/music-bin"
            className={`nav-link text-center ${
              path === "/music-bin" ? "active" : ""
            }`}
          >
            <FontAwesomeIcon icon={solid("record-vinyl")} />
            <span className="d-block"> music bin</span>
          </Link>
          {authedUser === "true" ? (
            <Link
              to="/profile"
              className={`nav-link text-center ${
                path === "/profile" ? "active" : ""
              }`}
            >
              <FontAwesomeIcon icon={solid("id-card")} />
              <span className="d-block"> my profile</span>
            </Link>
          ) : (
            ""
          )}
         
        </Nav> <div className="d-flex align-items-center">
          <Web3Auth
            onConnectAccount={() => {
              setConnected(true);
            }}
            onDisconnectAccount={() => {
              setConnected(false);
            }}
          />
          </div>
      </Navbar.Collapse>
    </Navbar>
  );
}
export default Menu;
