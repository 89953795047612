import { Button } from 'antd';
import React from 'react';
import Address from './Address';
import Balance from './Balance';
import {metaMaskLogo,disconnect} from '../../assets/img/index';
import {useHistory} from "react-router-dom"
export default function Account({
  address,
  userProvider,
  price,
  web3Modal,
  loadWeb3Modal,
  logoutOfWeb3Modal,
  blockExplorer,
  changeAccountState,
  DisconnectAccountState
}){
  let history=useHistory();
  const modalButtons= [];


  async function Disconnect(){
    await logoutOfWeb3Modal();
    DisconnectAccountState();
    history.push("/home");
  }

  async function Connect(){
    await loadWeb3Modal();
    changeAccountState();
    history.push("/my-library")
  }

  if (web3Modal) {
    if (web3Modal.cachedProvider) {
      modalButtons.push(
        <>
          {/* <Address address={address} blockExplorer={blockExplorer} /> */}
          {/* <Balance address={address} provider={userProvider} price={price} /> */}
        <img src={disconnect} className="pay-metamask-logo disconnect" onClick={Disconnect}/>

        </>
      );
    } else {
      modalButtons.push(
        <img src={metaMaskLogo} className="pay-metamask-logo" onClick={Connect}/>
      );
    }
  }

  return <div>{modalButtons}</div>;
}
