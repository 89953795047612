import React, { useEffect, useState } from "react";
import InnerPagesLayout from "../component/InnerPagesLayout";
import { Spinner } from "react-bootstrap";
import ManageMyNftTableSingle from "../component/manageMyNftTable/manageMyNftTableSingle";
import {
  TableBody,
  TableHead,
  Table,
  IconButton,
  TableCell,
  TableRow,Menu,MenuItem
} from "@mui/material";

const ManageMyNfts = () => {
  const [loading, setLoading] = useState(true);
  const [userNFT, setUserNFT] = useState();
  const myNft = [
    {
      id: 1,
      title: "nft1",
      desc: "desc1",
      activeCards: "2",
      cards: [
        {
          id: 1,
          cardNumber: "1",
          ownerWallet: "078hjdabjjh",
          currentRewards: 2,
        },
        {
          id: 2,
          cardNumber: "2",
          ownerWallet: "gtykkkk078hjdabjjh",
          currentRewards: 1,
        },
      ],
    },
    {
      id: 2,
      title: "nft2",
      desc: "desc2",
      activeCards: "1",
      cards: [
        {
          id: 1,
          cardNumber: "1",
          ownerWallet: "078hjdabjjh",
          currentRewards: 2,
        },
      ],
    },
  ];


  useEffect(() => {
    setUserNFT(myNft);
    setLoading(false);
  }, []);

  return (
    <InnerPagesLayout>
      <section id="section_12" className="about_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {!!loading ? (
                <div className="row justify-content-center">
                  <div className="col-lg-12 col-md-12 col-sm-12 text-center mb-4">
                    <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                      className="text-accent"
                    />
                  </div>
                </div>
              ) : (
                <>
                 <h4 className="text-center mb-4">Manage My NFts</h4>
                  <Table>
                    <TableHead>
                      <TableRow className="sub__table">
                        <TableCell className="market-volume"></TableCell>
                        <TableCell className="market-volume">Title</TableCell>
                        <TableCell className="market-volume">
                          Description
                        </TableCell>
                        <TableCell className="market-volume">
                          Active Cards
                        </TableCell>
                        <TableCell className="market-volume">
        
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userNFT &&
                        userNFT.map((nft, index) => (
                          <ManageMyNftTableSingle item={nft} key={index} />
                        ))}
                    </TableBody>
                  </Table>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </InnerPagesLayout>
  );
};
export default ManageMyNfts;
