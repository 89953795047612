import React, { useState } from "react";
import InnerPagesLayout from "../component/InnerPagesLayout";
import validator from "validator";
import contact from "../assets/img/BG/contact.png";
import { AnimatedOnScroll } from "react-animated-css-onscroll";

function Contact() {
  const IntialInputs = () => ({
    inputs: {
      name: "",
      email: "",
      wallet: "",
      subject: "",
      message: "",
    },
  });
  const [state, setState] = useState(IntialInputs());
  const [showErrors, setShowErrors] = useState();

  const handleِSave = async () => {
    setShowErrors(true);
    const { name, email, wallet, subject, message } = state.inputs;
    if (
      name !== "" &&
      email !== "" &&
      wallet !== "" &&
      subject !== "" &&
      message !== ""
    ) {
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    const { inputs } = state;

    inputs[name] = value;
    setState({
      ...state,
      inputs,
    });
  };

  return (
    <InnerPagesLayout>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-center mt-5">
          <div className="col-5">
            <AnimatedOnScroll animationIn="fadeInDown" isVisible={true}>
              <img src={contact} className="img img-fluid" alt="contact" />
            </AnimatedOnScroll>
          </div>
        </div>
        <AnimatedOnScroll animationIn="fadeInUp" isVisible={true}>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-7">
              {" "}
              <div className="row form-group mt-5">
                <div className="col-6 text-center">
                  <input
                    type="text"
                    name="name"
                    value={state.inputs.name}
                    onChange={handleChange}
                    placeholder="Full Name*"
                    className="form-control"
                  />
                  {showErrors === true &&
                    validator.isEmpty(state.inputs.name) && (
                      <div className="pay-beats-error-msg">Required</div>
                    )}
                </div>
                <div className="col-6 text-center">
                  <input
                    type="email"
                    name="email"
                    value={state.inputs.email}
                    onChange={handleChange}
                    placeholder="Email*"
                    className="form-control"
                  />
                  {showErrors === true &&
                    validator.isEmpty(state.inputs.email) && (
                      <div className="pay-beats-error-msg">Required</div>
                    )}
                </div>
              </div>
              <div className="row form-group">
                <div className="col-6 text-center">
                  <input
                    type="text"
                    name="wallet"
                    value={state.inputs.wallet}
                    onChange={handleChange}
                    placeholder="Wallet Public Key"
                    className="form-control"
                  />
                  {showErrors === true &&
                    validator.isEmpty(state.inputs.wallet) && (
                      <div className="pay-beats-error-msg">Required</div>
                    )}
                </div>
                <div className="col-6 text-center">
                  <input
                    type="text"
                    name="subject"
                    value={state.inputs.subject}
                    onChange={handleChange}
                    placeholder="subject"
                    className="form-control"
                  />
                  {showErrors === true &&
                    validator.isEmpty(state.inputs.subject) && (
                      <div className="pay-beats-error-msg">Required</div>
                    )}
                </div>
              </div>
              <div className="row form-group">
                <div className="col-lg-12">
                  <textarea
                    value={state.inputs.message}
                    className="form-control"
                    name="message"
                    placeholder="Message"
                    onChange={handleChange}
                  />
                  {showErrors === true &&
                    validator.isEmpty(state.inputs.message) && (
                      <div className="pay-beats-error-msg">Required</div>
                    )}
                </div>
              </div>
              <div className="row form-group">
                <div className="col-lg-12">
                  <button
                    className="btn btn-warning btn-lg"
                    onClick={() => {
                      handleِSave();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </AnimatedOnScroll>
      </div>
    </InnerPagesLayout>
  );
}
export default Contact;
