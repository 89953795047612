export const SET_AUTHED_USER = 'SET_AUTHED_USER'
export const SET_PLAY_LIST = 'SET_PLAY_LIST'
export const SET_PLAYED_NFT = 'SET_PLAYED_NFT'
export const SET_IS_PLAYED = 'SET_IS_PLAYED'
export const SET_IS_PAUSED = 'SET_IS_PAUSED'

export function setauthedUser (flag) {
  return {
    type: SET_AUTHED_USER,
    flag,
  }
}

export function setplayList (playList) {
  return {
    type: SET_PLAY_LIST,
    playList,
  }
}

export function setPlayedNFT (track) {
  return {
    type: SET_PLAYED_NFT,
    track,
  }
}

export function setIsPlayedNFT (flag) {
  return {
    type: SET_IS_PLAYED,
    flag,
  }
}

export function setIsPaused (flag) {
  return {
    type: SET_IS_PAUSED,
    flag,
  }
}