import { SET_AUTHED_USER ,SET_PLAY_LIST,SET_IS_PLAYED,SET_PLAYED_NFT,SET_IS_PAUSED} from '../actions/action';

export default function getData (state = {authedUser:"false",playList:[],isNftPlayed:"false",playedNft:{},isPaused:"false"}, action) {
  switch (action.type) {
    case SET_AUTHED_USER :
      return {...state,authedUser:action.flag}
      case SET_PLAY_LIST :
        return {...state,playList:action.playList}
      case SET_IS_PLAYED :
        return {...state,isNftPlayed:action.flag}
        case SET_PLAYED_NFT :
        return {...state,playedNft:action.track}
        case SET_IS_PAUSED :
        return {...state,isPaused:action.flag}
    default :
      return state
  }
}
